import { DISPLAY_FOOTER } from '../actions/FooterAction';

const initialState = {
    display: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case DISPLAY_FOOTER:
            return { ...state, display: action.payload };
        default:
            return state
    }
}
