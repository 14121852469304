import React, { Component } from 'react'
import './ResultKatz.css'
import { Button, Col, Row, Container } from 'reactstrap';
import ResumeCards from './Components/ResumeCards/ResumeCards';
import Input from '../CalculateKatz/Components/Input/Input';
import { changeScoreKatz } from '../../services/redux/actions/ScoreAction';
import { changeDisplayFooter } from '../../services/redux/actions/FooterAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { bindActionCreators, compose } from 'redux';
import Desoriented from './Components/Desoriented/Desoriented';
import Incontienence from './Components/Incontienence/Incontienence';
import Result from './Components/Result/Result';
import { Link, NavLink, useLocation } from 'react-router-dom';
import JsxParser from 'react-jsx-parser';
import { RepeatRounded } from '@material-ui/icons';
import Working from './Components/Working/Working';
import Connection from '../Welcome/Components/Connection/Connection';
import AdsBanner from '../../components/AdsBanner/AdsBanner';

class ResultKatz extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scores: ["", "", "", "", "", ""],
            levelDesorientation: 0,
            incontienence: false,
            quota: (localStorage.getItem("resultat") - 1)
        }
        this.interpretation = ''
    }

    componentDidMount() {
        document.title = "Hygie Katz - Result";
        var val = parseInt(localStorage.getItem("resultat"));
        if (!this.props.connect && val > 0) {
            //DECREMENTATION DU LOCAL STORAGE 
            val--;
            localStorage.setItem("resultat", val);
        }
        if (this.state.quota < 0) {
            this.setState({ quota: 0 })
        }
    }

    onChangeDesorientation(value) {
        this.setState({
            levelDesorientation: value
        })
    }

    onChangeIncontinence(value) {
        this.setState({
            incontienence: value
        })
    }

    desorientationMessage() {
        switch (parseInt(this.state.levelDesorientation)) {
            case 1:
                return (
                    <div className="resultkatz__desorientation--message">
                        <JsxParser jsx={this.props.t('low_desorientation')} />
                    </div>
                )
            case 2:
                return (
                    <div className="resultkatz__desorientation--message">
                        <JsxParser jsx={this.props.t('middle_desorientation')} />
                    </div>
                )
            case 3:
                return (
                    <div className="resultkatz__desorientation--message">
                        <JsxParser jsx={this.props.t('high_desorientation')} />
                    </div>
                )
            default: return null
        }
    }



    render() {
        var margin_mobile = "resultrow__margin--top"

        if (window.innerWidth < 450) {
            margin_mobile = "resultrow__margin--top--mobile"
        }
        else {
            margin_mobile = "resultrow__margin--top"
        }

        this.props.changeDisplayFooter(true)
        const { t } = this.props;
        return (
            <>
                <Container className="--paddingBottom">
                    <Row>
                        <Col xs={{ size: 0, offset: 0 }} sm={{ size: 12, offset: 0 }} md={{ size: 11, offset: 1 }} lg={{ size: 5, offset: 1 }} xl={{ size: 5, offset: 1 }}>
                            <Row>
                                <Col>
                                    <Input ref={this.inputRef} scores={this.props.score}
                                        changeScores={this.changeKatzScore.bind(this)}
                                        score={this.props.score}
                                        changeKatzScoreWithStr={this.changeKatzScoreWithStr.bind(this)}
                                    />

                                </Col>
                            </Row>
                            <Row className="result__margin--top">

                                <Col xs={6} sm={4} className="result__center--card">
                                    <NavLink to={{
                                        pathname: '/calculatekatz',
                                        state: { index: 0 }
                                    }}
                                        className="result__div--click">
                                        <ResumeCards
                                            score={this.props.score[0]}
                                            index={0}
                                            translate={t}
                                        />
                                    </NavLink>
                                </Col>
                                <Col xs={6} sm={4} className="result__center--card">
                                    <NavLink to={{
                                        pathname: '/calculatekatz',
                                        state: { index: 1 }
                                    }} className="result__div--click">
                                        <ResumeCards
                                            score={this.props.score[1]}
                                            index={1}
                                            translate={t} />
                                    </NavLink>
                                </Col>
                                <Col xs={6} sm={4} className="result__center--card">
                                    <NavLink to={{
                                        pathname: '/calculatekatz',
                                        state: { index: 2 }
                                    }} className="result__div--click">
                                        <ResumeCards
                                            score={this.props.score[2]}
                                            index={2}
                                            translate={t} />
                                    </NavLink>
                                </Col>

                                <Col xs={6} sm={4} className="result__center--card">
                                    <NavLink to={{
                                        pathname: '/calculatekatz',
                                        state: { index: 3 }
                                    }} className="result__div--click">
                                        <ResumeCards
                                            score={this.props.score[3]}
                                            index={3}
                                            translate={t} />
                                    </NavLink>
                                </Col>
                                <Col xs={6} sm={4} className="result__center--card">
                                    <NavLink to={{
                                        pathname: '/calculatekatz',
                                        state: { index: 4 }
                                    }} className="result__div--click">
                                        <ResumeCards
                                            score={this.props.score[4]}
                                            index={4}
                                            translate={t} />
                                    </NavLink>
                                </Col>
                                <Col xs={6} sm={4} className="result__center--card">
                                    <NavLink to={{
                                        pathname: '/calculatekatz',
                                        state: { index: 5 }
                                    }} className="result__div--click">
                                        <ResumeCards
                                            score={this.props.score[5]}
                                            index={5}
                                            translate={t} />
                                    </NavLink>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={{ size: 12, offset: 0 }} sm={{ size: 12, offset: 0 }} md={{ size: 11, offset: 1 }} lg={{ size: 5 }} xl={{ size: 4 }} className={margin_mobile}>
                            <Row>
                                <Col>
                                    <div className="result__text">
                                        <Desoriented
                                            changeDesorientation={this.onChangeDesorientation.bind(this)}
                                        />
                                        <Incontienence
                                            translate={t}
                                            changeIncontinence={this.onChangeIncontinence.bind(this)} />
                                        <Result
                                            translate={t}
                                            score={this.props.score} />

                                        {this.desorientationMessage()}
                                        {this.displayResult()}

                                        <Working
                                            translate={t}
                                            value={this.interpretation}
                                        />

                                        <div className="d-none d-md-block">
                                            <AdsBanner isMobile={false} />
                                        </div>
                                    </div>
                                    {this.quotaNull()}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <div className="d-md-none">
                    <AdsBanner isMobile={true} />
                </div>
            </>
        )
    }

    quotaNull() {
        if (!this.props.connect) {
            return (
                <div className="result__text--message">
                    {this.props.t("result_messageFP")} <label className="result__text--orange">{this.state.quota}</label> {this.props.t("result_messageSP")}
                    <Connection url={window.location.pathname} />
                </div>
            )
        }
    }

    changeKatzScore(tabIndex, value) {

        var newTab = this.state.scores;
        newTab[tabIndex] = value;
        this.setState({
            scores: newTab
        })
        // this.props.changeScoreKatz(this.state.scores)
    }

    changeKatzScoreWithStr(str) {
        var newTab = this.state.scores;
        var tabStr = str.split("");
        //la partie avec le scoren est assez différente de Native
        // on remplis un tableau avec la nouvelle valeur puis on change le state avec le nouveau tableau
        for (let i = 0; i < 6; i++) {

            newTab[i] = tabStr[i];
            if (typeof (newTab[i]) === "undefined") {
                newTab[i] = "";
            }
        }
        this.setState({
            scores: newTab
        })
        this.props.changeScoreKatz(this.state.scores)
    }

    // calcul pour avoir le résultat de katz ( toilette/forait)
    displayResult() {
        var temp = this.props.score + ''
        var score = temp.split(',').map(function (element) {
            return parseInt(element)
        })

        var wash = score[0];
        var dress = score[1];
        var trans = score[2];
        var toilet = score[3];
        var cont = score[4];
        var eat = score[5];
        var value = "null"

        if (wash != 0 && dress != 0 && trans != 0 && toilet != 0 && cont != 0 && eat != 0) {

            if (wash === 4 && dress === 4 && trans === 4 && toilet === 4 && cont >= 3 && eat >= 3 && (cont === 4 || eat === 4)) {
                value = 'F_C';
            }
            else {
                if (wash >= 3 && dress >= 3 && trans >= 3 && toilet >= 3 && (cont >= 3 || eat >= 3)) {
                    value = 'F_B';
                }
                else {
                    if (wash >= 3 && dress >= 3 && (trans >= 3 || toilet >= 3)) {
                        value = 'F_A';
                    }
                    else {
                        if ((wash >= 2 && dress >= 2 && cont >= 3) ||
                            (wash >= 2 && dress >= 2 && cont == 2 && this.state.incontienence) ||
                            (wash >= 2 && dress >= 2 && cont <= 2 && (this.state.levelDesorientation == 2 || this.state.levelDesorientation == 3)) ||
                            (wash == 4 && dress == 4)) {
                            value = 'T7';
                        }
                        else {
                            if (wash >= 2) {
                                value = 'T2';
                            }
                            else {
                                value = 'impossible';
                            }
                        }
                    }
                }
            }
        }
        else {
            value = ''
        }

        this.interpretation = value;
    }
}

function mapStateToProps(store) {

    let display = '';
    if (typeof store.footer.display !== 'undefined') {
        display = store.footer.display;
    }

    return {
        display: display,
        score: store.score.score,
        connect: store.connect.connect
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeDisplayFooter: changeDisplayFooter,
        changeScoreKatz: changeScoreKatz

    }, dispatch)
}


export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(ResultKatz);