import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import JsxParser from 'react-jsx-parser'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'
import { bindActionCreators, compose } from 'redux'
import { changeDisplayFooter } from '../../services/redux/actions/FooterAction'
import Connection from '../Welcome/Components/Connection/Connection'
import './Quota.css'

class Quota extends Component {
    
    constructor(props)
    {
        super(props)
    }

    componentDidMount() {
        document.title = "Hygie Katz - Quota"
        this.props.changeFooter(false);
    }
    
    render() {
        const {t} = this.props;

        var margin_firstRow='quota__firstrow';
        var margin_secondRow ='quota_secondrow';
        var quota_title = 'quota__title'

        if (window.innerWidth < 450) {
            margin_firstRow = 'quota__firstrow--mobile';
            margin_secondRow ='quota_secondrow--mobile';
            quota_title = 'quota__title--mobile';
        }
        else {
            margin_firstRow = "quota__firstrow";
            margin_secondRow ='quota_secondrow';
            quota_title = 'quota__title';
        }

        return (
            <Container className="quota__container">
                <Row className={margin_firstRow}>
                    <Col className="mt-5" xs={{ size: 12 }} sm={{ size: 11, offset: 1 }} md={{ size: 11, offset: 1 }} lg={{ size: 11, offset: 1 }} xl={{ size: 11, offset: 1 }}>
                        <div className={quota_title}> {t('quota_exceded')} </div>
                        <div className="quota__utilisation">3/3 {t('quota_uses')} </div>
                    </Col>
                </Row>
                <Row className={margin_secondRow}>
                    <Col className="mt-5" xs={{ size: 12 }} sm={{ size: 11, offset: 1 }} md={{ size: 11, offset: 1 }} lg={{ size: 11, offset: 1 }} xl={{ size: 11, offset: 1 }}>
                      <span className="quota__message"><JsxParser jsx={t("quota_message")} /></span> 
                        <Connection url={window.location.pathname}/>
                    </Col>
                </Row>
            </Container>
        )
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeFooter: changeDisplayFooter
    }, dispatch)
}

export default compose(connect(null, mapDispatchToProps), withTranslation())(Quota);