import React, { Component } from 'react';
import CardDescription from '../CardDescription/CardDescription';
import './ViewsDescription.css';

import wash from '../../../../assets/Images/wash.svg';
import dress from '../../../../assets/Images/dress.svg';
import transfer from '../../../../assets/Images/transfert.svg';
import toilet from '../../../../assets/Images/toilet.svg';
import continence from '../../../../assets/Images/continence.svg';
import eat from '../../../../assets/Images/eat.svg';

import { Redirect } from 'react-router-dom';


const images = [
    wash,
    dress,
    transfer,
    toilet,
    continence,
    eat
]

const ImageDescriptionDisplayTime = 1500;

export class ViewDescription extends Component {
    constructor(props) {
        super(props);

        this.tabDescriptionRef = [];
        this.ref = React.createRef();
        this.childrenVisibility = " hidde"
        this.state = {
            displayImage: false,
            indexClicked: 0,
        }
        this.displayCardsDescription = this.displayCardsDescription.bind(this);
    }


    slideCardDescriChangePage(index) {
        if (index == 0 && this.tabDescriptionRef[index] != null) {
            this.tabDescriptionRef[index].slideTextRightLeft();
            index++;
        }

        setTimeout(() => {

            if (index < this.tabDescriptionRef.length && this.tabDescriptionRef[index] !== null) { //tant qu'on est pas au dernier index c est qu il a des card description à anim

                this.tabDescriptionRef[index].slideTextRightLeft();
                index += 1;

                this.slideCardDescriChangePage(index);
            }
        }, 250)
    }

    onPressLevel(indClicked) {
        indClicked = indClicked + "";
        this.childrenVisibility = " visible";

        for (var i = 0; i < this.tabDescriptionRef.length; i++) {
            if(this.tabDescriptionRef[i] != null) {
                this.tabDescriptionRef[i].slideDown(indClicked);
            }
        }


        setTimeout(() => {
            this.setState({
                indexClicked: indClicked,
                displayImage: true,
            });

            setTimeout(() => {
                this.setState({

                    displayImage: false,
                })

                if(this.props.index == 6) {
                    this.setState({ redirect: "/resultkatz" });
                }

                this.props.changeScores(this.props.index - 1, indClicked);
                this.props.selectPage();
                
            }, ImageDescriptionDisplayTime);
            

        }, 50)
    }

    displayCardsDescription(elements) {
        if (this.state.displayImage == false) {
            for (let i = 0; i < 4; i++) {
                const index = i + 1;
                elements.push(
                    <CardDescription ref={(ref) => { this.tabDescriptionRef[i] = ref }}
                        id={"description" + index}
                        onClick={() => this.onPressLevel(index)}
                        translate={this.props.translate}
                        index={index}
                        cardName={this.props.cardName}
                        cardIndex = {this.props.index}
                        visibility={this.childrenVisibility}
                        key={i}
                        score = {this.props.score}
                    ></CardDescription>
                );
            }
        }
        else {
            const index = this.state.indexClicked;

            elements.push(
                <div key={index + 1}>
                    <div className="view__image--description view__image--slide--bottom">
                        <img className="view__image" src={images[this.props.index - 1]}></img>
                    </div>
                </div>
            )
            elements.push(

                <CardDescription ref={(ref) => { this.tabDescriptionRef[index - 1] = ref }}
                    id={"description" + index}
                    onClick={() => this.onPressLevel(index)}
                    translate={this.props.translate}
                    index={index}
                    cardName={this.props.cardName}
                    visibility=" absolute"
                    score = {this.props.score}
                    key={index}
                ></CardDescription>
            )
        }

    }

    slideCardDescri(index) {

        setTimeout(() => {
            if (this.tabDescriptionRef[index -1] !== null) {
                if (index > 0) {
                    this.tabDescriptionRef[index - 1].slideTextStart();
                }
                if (index == this.tabDescriptionRef.length) { // quand on a fini on dit au parent qu'il peut autre chose

                }
                if (index < this.tabDescriptionRef.length) { //tant qu'on est pas au dernier index c est qu il a des card description à anim

                    this.tabDescriptionRef[index].slideDivStart();
                    index += 1;

                    this.slideCardDescri(index);
                }
            }
        }, 250)
    }


    timerSlideMain() {

        setTimeout(() => {

            this.slideCardDescri(0);
        }, 0);


    }

    async asyncCall() {

        await this.timerSlideMain();

    }

    componentDidMount() {

        this.asyncCall();
    }

    render() {
        const elements = [];

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        return (
            <div>

                {this.displayCardsDescription(elements)}

                {elements}
            </div>
        )
    }
}


