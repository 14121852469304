import React, { Component } from 'react'
import './CardDescription.css'

const fadeClik = 250;

export default class CardDescription extends Component {

    constructor(props) {
        super(props);

        this.classBase = "";

        this.parentRef = React.createRef();
        this.textRef = React.createRef();

    }

    async onClickAnimation() {
        this.parentRef.current.className = this.classBase ;//+ " fadeOutAndIn";
        await this.onClick();
    }

    onClick() {
        setTimeout(() => {

            this.props.onClick();

        }, fadeClik)
    }

    render() {

        this.classBase = "card__row--level card__row--level--" + this.props.index;
        if (this.props.visibility == " absolute") {
            this.classBase += " card__absolute";
        }
        var cercleStyle = "";
        var number = this.props.score[this.props.cardIndex - 1];
        number = parseInt(number);
        if (number > 0) {

            if (number === 1 && number === this.props.index) {

                cercleStyle = "card__wrapper--1"
            }
            
            else if (number > 1 && number === this.props.index) {
                cercleStyle = "card__wrapper--2-4"
            }
        }

        return (

            <div ref={this.parentRef} onClick={() => this.onClickAnimation()}
                className={this.classBase + this.props.visibility}>

                <div ref={this.textRef} className={"card__text--parent " + this.props.visibility} >
                    <div className={"card__level "} >  <span className={cercleStyle}> <span > {this.props.index}</span> </span> </div>
                    <div className={"card__text "}> <span> {
                    this.props.translate(this.props.cardName + this.props.index).charAt(0).toUpperCase() + this.props.translate(this.props.cardName + this.props.index).slice(1)
                    } </span> </div>
                </div>
            </div>

        )
    }

    async slideTextRightRight() {

    }

    async slideTextRightLeft() {
        // this.styleDivText = "card__text--parent " + " visible" + " rcSlideDescription";
        // this.textRef.current.className = this.styleDivText;
        // setTimeout(() => {
        //     if (this.textRef.current != null) {

        //         this.styleDivText = "card__text--parent " + " visible";
        //         this.textRef.current.className = this.styleDivText;
        //     }
        // }, 250)


    }

    //au pop de la page 
    slideDivStart() {
        this.visibility = " visible";

        this.parentRef.current.className = this.classBase + " CardDescriptioslide--Fade";
    }
    slideTextStart() {

        this.styleDivText = "card__text--parent " + " visible" + " CardDescriptioslide--Fade";
        this.textRef.current.className = this.styleDivText;
    }

    slideDown(indexCliked) {

        if (this.props.index != indexCliked) {

            this.textRef.current.className = " card__hidde";
            this.parentRef.current.className = this.classBase + " card__slideDown" + this.props.index;
        }
        else {
            this.parentRef.current.className = this.classBase + " card__slideDown" + this.props.index;
        }


    }

    setZIndexPriority() {
        this.parentRef.current.className = this.classBase;
    }
}
