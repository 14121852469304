import React, { Component } from 'react'
import Switch from 'react-switch'
import './Incontienence.css'
export default class Incontienence extends Component {
    constructor() {
        super();
        this.state = { checked: false };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(checked) {
        this.setState({ checked });
        this.props.changeIncontinence(checked)
    }
    render() {

        const { translate } = this.props;
        return (
            <div className="continence__container">
                <div className="continence__text--switch">
                    <div className="continence__text">
                        {translate('continence_message')}
                    </div>
                    <div className="continence__switch">
                        <Switch
                            onChange={this.handleChange}
                            checked={this.state.checked}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            height={22}
                            width={48}
                            onColor={"#FE5000"}
                            onHandleColor={"#000000"}
                            handleDiameter={18}
                        />
                    </div>
                </div>
                <div className="continence__explication--text">
                    {translate('continence_explication')}
                </div>
            </div>
        )
    }
}
