import React, { Component } from 'react'
import SummariseCard from './Components/SummariseCard/SummariseCard'

export default class ResumeCards extends Component {
    
    render() {
        return (
            <div className="summcard__padding--right">
                
                <SummariseCard
                index={this.props.index}
                translate ={this.props.translate}
                score={this.props.score}/>
            </div>
        )
    }
}
