import React, { Component } from 'react'
import { Col, Row, Container } from 'reactstrap'
import './Explication.css'
import { ReactComponent as Validation } from '../../assets/SVG/validation_vide.svg'
import { changeDisplayFooter } from '../../services/redux/actions/FooterAction'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import JsxParser from 'react-jsx-parser'
import ReactPlayer from 'react-player'


class Explication extends Component {
    //Page de tutoriel
    constructor(props){
        super(props)
        this.props.changeFooter(false);
    }

    componentDidMount(){this.props.changeFooter(true);}
    render() {
        var margin_col_left = "explication__col--left"
        var margin_col_right = "explication__col--right"

        if (window.innerWidth < 450) {
            margin_col_left = "explication__col--left--mobile"
            margin_col_right = "explication__col--right--mobile"
        }
        else {
            margin_col_left = "explication__col--left"
            margin_col_right = "explication__col--right"

        }
        // la partie en commentaire = video 
        return (
            <Container>
                <Row >
                    <Col xs={{ size: 12, offset: 0 }} sm={{ size: 12, offset: 0 }} md={{ size: 11, offset: 1 }} lg={{ size: 5, offset: 1 }} xl={{ size: 5, offset: 1 }}>
                        <Row className={margin_col_left}>
                            <Col xs={{ size: 12, offset: 0 }} sm={{ size: 12, offset: 0 }} md={{ size: 10 }} lg={{ size: 10 }} xl={{ size: 10 }}>
                                <div className="explication__title--left">
                                    Tuto <span className="explication__Hygie">HYGIE</span> Katz
                                 </div>
                                <div className="explication__border--left">
                                    <div className="explication__text--left">
                                        <JsxParser jsx={this.props.t('tuto')} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                    {/*<Col xs={{ size: 0, offset: 0 }} sm={{ size: 12, offset: 0 }} md={{ size: 11, offset: 1 }} lg={{ size: 5, offset: 1 }} xl={{ size: 5, offset: 1 }}>
                        <Row>
                            <Col className={margin_col_right}>
                                        {this.props.t("video")}
                                <div className="explication__video--right">
                                  <ReactPlayer 
                                  url='https://www.youtube.com/watch?v=LXb3EKWsInQ'
                                  className="explication__video--right"
                                  width={'100%'}
                                  height={'100%'}
                                  />
                                </div>

                            </Col>
                        </Row>
        </Col>*/}
                </Row>
            </Container>
        )
    }
}
function mapStateToProps(store) {
    return {
        score: store.score.score
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeFooter: changeDisplayFooter

    }, dispatch)
}


export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Explication);
