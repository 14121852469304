import React, { Component } from 'react'
import { Button, Col, Row, Container } from 'reactstrap';
import { changeDisplayFooter } from '../../services/redux/actions/FooterAction'
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import Input from './Components/Input/Input';
import Cards from './Components/Cards/Cards';
import './CalculateKatz.css';
import { withTranslation } from 'react-i18next';
import InputKatz from './Components/InputKatz/InputKatz';
import { changeScoreKatz } from '../../services/redux/actions/ScoreAction';
import Quota from '../Quota/Quota';

class CalculateKatz extends Component {

    constructor(props) {
        super(props);

        this.state = {
            cardIndex: this.chooseIndex()
            ,

            tabCard: [
                'wash',
                'dress',
                'transfer',
                'toilet',
                'continence',
                'eat',
            ],
            scores: ["", "", "", "", "", ""]

        };
        this.props.changeDisplayFooter(false);
    }

    chooseIndex() {
        if (this.props.location.state == undefined) {
            return 0;
        }
        else {
            return this.props.location.state.index;
        }
    }

    componentDidMount() {
        document.title = "Hygie Katz - Calcul"
        this.props.changeDisplayFooter(true);
        if (this.props.location.state != undefined) {
            this.selectPage(this.props.location.state.index)
            this.setState({
                //cardIndex : this.props.location.state.index,
                scores: this.props.score
            })
        }
        else {
            this.setState({
                cardIndex: 0
            })
        }


    }



    render() {
        const { t } = this.props;
      
        return (
            <Container>

                <Row className="" >
                    <Col xs={{ size: 12, offset: 0 }} sm={{ size: 12, offset: 0 }} md={{ size: 10, offset: 1 }} lg={{ size: 5, offset: 1 }} xl={{ size: 5, offset: 1 }}>
                        <Input ref={this.inputRef} scores={this.state.scores}
                            changeScores={this.changeKatzScore.bind(this)}
                            score={this.state.scores}
                            changeKatzScoreWithStr={this.changeKatzScoreWithStr.bind(this)}
                            selectPage={this.selectPage.bind(this)}
                        >
                        </Input>

                    </Col>
                </Row>
                <Row className="mt-3">

                    <Col xs={{ size: 12, offset: 0 }} sm={{ size: 12, offset: 0 }} md={{ size: 8, offset: 2 }} lg={{ size: 5, offset: 1 }} xl={{ size: 5, offset: 1 }}>

                        <Cards
                            translate={this.props.t}
                            cardIndex={this.state.cardIndex}
                            tabCard={this.state.tabCard}
                            selectPage={this.selectPage.bind(this)}
                            changeScores={this.changeKatzScore.bind(this)}
                            score={this.state.scores}
                        ></Cards>
                    </Col>

                </Row>

            </Container>
        )
    }


    changeKatzScore(tabIndex, value) {
        var newTab = this.state.scores;
        newTab[tabIndex] = value;
        this.setState({
            scores: newTab
        })
        this.props.changeScoreKatz(this.state.scores)
    }

    changeKatzScoreWithStr(str) {
        var newTab = this.state.scores;
        var tabStr = str.split("");

        for (let i = 0; i < 6; i++) {

            newTab[i] = tabStr[i];
            if (typeof (newTab[i]) === "undefined") {
                newTab[i] = "";
            }
        }

        this.setState({
            scores: newTab
        })
        this.props.changeScoreKatz(this.state.scores)
    }


    selectPage(index) {

        if (index < 6) {

            this.setState({
                cardIndex: index
            })
        }
    }
}

function mapStateToProps(store) {

    let display = '';
    if (typeof store.footer.display !== 'undefined') {
        display = store.footer.display;


    }

    return {
        display: display,
        score: store.score.score
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeDisplayFooter: changeDisplayFooter,
        changeScoreKatz: changeScoreKatz

    }, dispatch)
}


export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(CalculateKatz);
