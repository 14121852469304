import React, { Component } from 'react'

import { Button, Col, Row, Container } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';
import LanguageSelect from '../../services/I18N/languageSelect';
import logo from '../../assets/Images/logo.png'
import "./Header.css"
import { ReactComponent as Phone } from '../../assets/SVG/phone.svg'
import { ReactComponent as Logo } from '../../assets/SVG/logo.svg'
import { LaptopWindows } from '@material-ui/icons';
import { NavLink, Link } from 'react-router-dom';
export default class Header extends Component {

    constructor(props) {
        super(props);

    }

    render() {
        return (
            <Container>
                <Row className="header__row">

                    <Col xs={{ size: 8 }} md={{size: 8, offset: 1}}  >
                        <Link to="/">
                            <Logo/>
                        </Link>
                    </Col>
                    <Col xs={{ size: 2 }} md={{size: 1}} >
                        <LanguageSelect>

                        </LanguageSelect>
                    </Col>
                    <Col xs={{ size: 2 }} md={{size: 1}}  className="d-flex justify-content-end">
                        <button className="header__button"
                            onClick={() => window.open('tel:+32 69 57 57 62')}>

                            <span className="header__wrapper">
                                
                            </span>
                            <Phone ></Phone>
                        </button>
                    </Col>

                </Row>
            </Container>
        )
    }
}

