import React, { Component } from 'react'
import './Result.css'
export default class Result extends Component {
    //component pour afficher la toilette / forfait 
    render() {
                
        const { translate } = this.props;

        var str = this.props.score.toString()
        str = str.replace(/[,]+/g, "").trim();
        var score = str.replace(/(\d{2}(?!$))/g, "$1 ");

        return (
            <div className="result__container">
                <div className="result__text">
                    {translate('result')}
                </div>
                <div className="result__score">
                    {score}
                </div>
            </div>
        )
    }
}
