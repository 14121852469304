import React, { Component } from 'react'
import { Col, Row, Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import './ForgotPassword.css'
import Input from '../../components/Input/Input';
import WrapperButton from '../../components/WrapperButton/WrapperButton';
import axios from 'axios';
import * as constants from '../constants.js';



class ForgotPassword extends Component {
    //page ou on envoie le mail avec une requete post pour demander de changer le mdp
    constructor(props) {
        super(props);
        this.state = {

            jsonKey: "forgotPwd__mail",
            response: '',
            mail: '',

        }
    }

    render() {
        const { t } = this.props;
        return (
            <Container>
                <Row>
                    <Col xs={{ size: 12 }} sm={{ size: 11, offset: 1 }} md={{ size: 11, offset: 1 }} lg={{ size: 11, offset: 1 }} xl={{ size: 11, offset: 1 }}>
                        <h1 className="forgotPwd__title">
                            {t("forgotten_password")}
                        </h1>
                    </Col>
                </Row>
                <Row className="forgotPwd__explication--row">
                    <Col xs={{ size: 12 }} sm={{ size: 11, offset: 1 }} md={{ size: 8, offset: 1 }} lg={{ size: 5, offset: 1 }} xl={{ size: 5, offset: 1 }}>
                        <p className="forgotPwd__text">{t("forgotPwd__explication")}</p>
                        <Input
                            value={this.state.mail}
                            inputName={t("Email")}
                            placeholder={t("VHenderson@mail.com")}
                            onChange={this.handleChangeMail.bind(this)}
                            type={"text"}>
                        </Input>
                    </Col>
                </Row>
                <Row className="forgotPwd__mail--row">
                    <Col xs={{ size: 12 }} sm={{ size: 11, offset: 1 }} md={{ size: 8, offset: 1 }} lg={{ size: 5, offset: 1 }} xl={{ size: 5, offset: 1 }}>
                        <p className="forgotPwd__text">{t(this.state.jsonKey)}</p>
                        {this.displayButton()}

                    </Col>
                </Row>
            </Container>
        )
    }

    displayButton() {
        if (this.state.jsonKey === 'email_Password_NotSend') {
            return (
                <WrapperButton
                    onPressSubmit={() => this.onClikSendMail()}
                    text={"forgotPwd__button--mail"}>
                </WrapperButton>
            )
        }
        else if (this.state.jsonKey === 'email_Password_Send') {
            return null
        }
        else {
            return (
                <WrapperButton
                    onPressSubmit={() => this.onClikSendMail()}
                    text={"forgotPwd__button--mail"}>
                </WrapperButton>
            )
        }
    }

    onClikSendMail() {
        let url = constants.RESET_PWD_API_URL;//"";
        // if (process.env.NODE_ENV === "development") {
        //     url = constants.LOCAL_URL + resetPwdApiUrl; // 'https://localhost:5001/api/registration/newPassword'
        // } else {
        //     url = constants.PROD_URL + resetPwdApiUrl; //'https://api.hygie-katz.care/api/registration/newPassword'
        // }
    
        axios.post(url, {
            Email: this.state.mail.toString(),
            Language : localStorage.getItem("i18nextLng"),
            headers: {"Access-Control-Allow-Origin": "*"}

        })
            .then(res => {
                var resp = res.data;
                this.setState({
                    jsonKey: resp,
                })
            })
            .catch(res => {
            });
    }

    handleChangeMail(value) {
        var style = this.state.inputStyle;
        this.setState({ mail: value })
    }
}

export default compose(connect(null, null), withTranslation())(ForgotPassword);
