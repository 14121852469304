import React, { Component } from 'react'
import './InputKatz.css'
import { withTranslation } from 'react-i18next';
import { ReactComponent as Pen } from '../../../../assets/SVG/pen.svg'
import { ReactComponent as Validation } from '../../../../assets/SVG/validation.svg'



const spanClassNormal = "inputText inputText--black";
const spanClassGrey = "inputText inputText--grey";
const inputStyleNormal = "input input--normal";
const inputStyleFocus = "input input--onChange";
class InputKatz extends Component {

    constructor(props) {
        super(props);

        this.currentIndex = null;
        this.tabScores = [this.props.scores[0], this.props.scores[1], this.props.scores[2], this.props.scores[3], this.props.scores[4], this.props.scores[5]];
        this.refDiv = React.createRef();
        this.focus = false;
        
        this.state = {
            cardIndex: 0,
            isValidScore: false,
            
            tabCard: [
                'wash',
                'dress',
                'transfer',
                'toilet',
                'continence',
                'eat',
            ],

        };

    }

    componentDidMount() {
        this.changeClassName();
    }

    componentDidUpdate(prevprops){
        
        if(this.isScoreChanged())
        {

            this.forceUpdate();
            //this.checkValidScore();
        }
    }

    isScoreChanged(){
        for(let i =0; i < 6; i++)
        {
            var span = this.getSpan(i);
            var text = span.innerText;

            var score1 = parseInt(this.props.scores[i]);
            var score2 = parseInt(text);

            if(!isNaN(score2))
            {
                if(score1 != score2)
                {
                    this.tabScores[i] = score1;
                    return true;
                }
            }
            

        }
        return false;
    }

    render() {

        const { t } = this.props;
        var i = 0;

        let compo;
        
        if (this.state.isValidScore) {
            compo = <Validation className="pen"></Validation>
        }
        else {
            compo = <Pen className="pen"></Pen>;

        }

        return (
            <div className="parentInput">
                <span className="text">{t("yourKatzScore")}</span> <br></br>

                <div id="edit" ref={this.refDiv} suppressContentEditableWarning={true} 
                onBlur={this.onLeaveInput.bind(this)}
                    contentEditable={true}
                    className="ParentInputImage"
                    onKeyDown={this.keyDown}
                    onKeyUp={this.onWrite}
                    onClick={() => this.onInputClicked()} >
                    <div id="input" className={inputStyleNormal}>
                        <span id="sp0" className="inputText">{this.tabScores[0]}</span>
                        <span id="sp1" className="inputText">{this.tabScores[1]}</span>
                        <span id="sp2" className="inputText">{this.tabScores[2]}</span>
                        <span id="sp3" className="inputText">{this.tabScores[3]}</span>
                        <span id="sp4" className="inputText">{this.tabScores[4]}</span>
                        <span id="sp5" className="inputText">{this.tabScores[5]}</span>

                    </div>
                    {compo}
                </div>
            </div >
        )
    }

    checkValidScore()
    {
        let value = "";
        for(var i = 0; i < 6; i++){
            var span = this.getSpan(i);
            var text = span.innerText;
            var nbr = parseInt(text);
            if(nbr >0)
            {
                value += text;
            }
        }

        let nbrValue = parseInt(value);
        if(nbrValue > 99999)
        {
            this.setState({
                isValidScore : true,
            })
        }
        else{
            if(this.state.isValidScore == true)
            {
                this.setState({
                    isValidScore : false,
                })
            }
        }
    }

   

    changeClassName() {

        for (var i = 0; i < 6; i++) {
            var span = this.getSpan(i);
            var text = span.innerText;
            if (this.focus == true) {
                if (text == 0) {
                    span.className = spanClassGrey;
                }
                else {
                    span.className = spanClassNormal;
                }

            }
            else {
                span.className = "inputTextNormal";
            }

            if (i == 2 || i == 4) {
                span.className += " marginLeft";
            }
        }
    }

    onLeaveInput() {
        this.checkSpanAreNotEmpty();
        this.focus = false;
        this.changeClassName();
        var input = document.getElementById("input");
        input.className = inputStyleNormal;
    }

    checkSpanAreNotEmpty(){
        for(let i =0;i<6; i++ )
        {
            var span = this.getSpan(i);
            var text = span.innerText;
            if(text == "")
            {
                var txt = document.createTextNode(0);
                    span.appendChild(txt);
            }
        }
    }

    keyDown = (e) => {
        var value = e.key;
        if (value == "Backspace" || value == "Enter") {
            e.preventDefault();
            //this.resetSpanText(this.currentIndex);
        }
        
    }
    onWrite = (e) => {
        var value = e.key;

        if (value == "Backspace") {

            this.resetSpanText(this.currentIndex);
        }
        
        else if (value == "ArrowRight") {
            if (this.currentIndex < 5) {

                var span = this.getSpan(this.currentIndex);
                if (span.innerText == "") {
                    var txt = document.createTextNode(0);
                    span.appendChild(txt);
                }

                this.currentIndex += 1;
                span = this.getSpan(this.currentIndex);
                if (span.innerText == 0) {
                    this.resetSpanText(this.currentIndex);
                }
                this.changeFocus(this.currentIndex);
            }

        }
        else if (value == "ArrowLeft") {
            if (this.currentIndex > 0) {
                var span = this.getSpan(this.currentIndex);
                if (span.innerText == "") {
                    var txt = document.createTextNode(0);
                    span.appendChild(txt);
                }

                this.currentIndex -= 1;
                span = this.getSpan(this.currentIndex);
                if (span.innerText == 0) {
                    this.resetSpanText(this.currentIndex);
                }
                this.changeFocus(this.currentIndex);
            }

        }
        else {
            var span = this.getSpan(this.currentIndex);
            var text = span.innerText;
            var tabText = text.split("");
            
            if (parseInt(value) && tabText.length < 2) {
                
                var number = parseInt(value)
                if (number > 0 && number < 5) {
                    if (this.currentIndex < 5) {
                        var txt = document.createTextNode(number);
                        span.appendChild(txt);
                        this.currentIndex += 1;
                        this.resetSpanText(this.currentIndex)
                        this.changeFocus(this.currentIndex);
                    }
                    else {
                        
                       
                       
                        this.currentIndex += 1;
                    }
                    this.checkValidScore();
                    this.props.changeScores(this.currentIndex - 1, value);
                    this.changeClassName();
                    if (this.currentIndex == 6) {
                        this.currentIndex = 5;
                    }
                    return;
                }
            }
            this.resetSpanText(this.currentIndex);
        }
        this.changeClassName();
        this.checkValidScore();
    }

    resetSpanText(index) {
        var span = this.getSpan(index);
        span.innerHTML = "";
    }

    getSpan(index) {
        var a = document.getElementById("sp" + index);
        return a;
    }

    onInputClicked() {
        this.focus = true;
        var input = document.getElementById("input");
        input.className = inputStyleFocus;
        this.changeClassName();
        if (this.currentIndex == null) {
            this.currentIndex = 0;
        }

        if (this.checkSpanReset(this.currentIndex)) {
            this.resetSpanText(this.currentIndex);
        }
        this.changeFocus(this.currentIndex);
    }

    checkSpanReset(index) {
        var span = this.getSpan(index);
        if (span.innerText == 0 || span.innerText == "") {
            return true;
        }
        return false;
    }

    async changeFocus(index) {
        await setTimeout(() => {
            var el = document.getElementById("edit")

            var rangeobj = document.createRange();


            var selectobj = window.getSelection();

            var spanStart = this.getSpan(index);

            rangeobj.setStart(spanStart, 0);
            
            rangeobj.setEnd(this.getSpan(5), 0);
            rangeobj.collapse(true);

            selectobj.removeAllRanges();
            selectobj.addRange(rangeobj);

        }, 0)

    }

}

export default withTranslation()(InputKatz)
