import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationNDL from "../../assets/locales/ndl/translation.json";
import translationFR from "../../assets/locales/fr/translation.json";
import translationDE from "../../assets/locales/de/translation.json";


const availableLanguages = ["nl", "fr", "de"];

const resources = {
  nl: {
    translation: translationNDL
  },
  de: {
    translation: translationDE
  },
  fr: {
    translation: translationFR
  }
  
};



i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'fr',
    fallbackLng: "fr",
    resources,
    detection: {
      order: ["localStorage", "navigator"],
      lookupQuerystring: "lng",
      lookupLocalStorage: "i18nextLng",
      caches: ["localStorage"]
    },
    interpolation: {
      escapeValue: false,
    },
    
    
});



export default i18n;
