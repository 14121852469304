import React, { Component } from 'react'
import './SideCard.css'

export default class SideCard extends Component {

    constructor(props) {
        super(props);

        this.classCSS = "";
        this.visibility = " hidde ";
        this.baseClass = "";
        this.ref = React.createRef();
    }

    render() {

        this.classCSS += this.visibility;
        return (
            <div ref={this.ref} id="parent" onClick={() => this.props.startChangeCardAnim()} className={this.props.style}>
                {this.isTextDisplay() &&
                    <span className={this.props.styleText}>{this.props.index} - {this.props.translate(this.props.cardName)}</span>
                }
            </div>
        )
    }

    isTextDisplay(){
        if(this.props.index > 0 && this.props.index <7)
        {
            return true;
        }
        return false;
    }

    displayLeft() {

        this.baseClass = "leftCard";
        var classCSS = "leftCard";

        this.classCSS = classCSS;
    }

    displayRight() {
        this.baseClass = "rightcard";
        var classCSS = "rightcard";

        this.classCSS = classCSS;
    }

    displayRight2() {
        this.baseClass = "rightcard2";
        var classCSS = "rightcard2 ";

        this.classCSS = classCSS;

    }

    displayLeft2() {
        this.baseClass = "leftCard2";
        var classCSS = "leftCard2 leftCard--absolute";

        this.classCSS = classCSS;

    }

    onClick() {

        this.props.selectPage();
    }


    slide(value) {
        this.ref.current.className += value;
    }

    slideRight() {

        this.ref.current.className += " slideRight";
    }

    slideLeft() {

        this.ref.current.className += " slideLeft";
    }



    isNowVisible() {
        this.ref.current.className = this.baseClass + " visible";
    }

    isNowHdden() {
        this.ref.current.className = this.baseClass + " hidde";
    }
}
