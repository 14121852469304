import { CHANGE_SCORE} from '../actions/ScoreAction';

const initialState = {
    score: ['0','0','0','0','0','0']
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CHANGE_SCORE:
            return { ...state, score: [...action.payload] };
        default:
            return state
    }
}
