import React, { Component } from 'react'
import './SummariseCard.css'
/*
import { ReactComponent as Wash }  from '../../../../../../assets/Images/wash.svg'
import { ReactComponent as Dress }  from '../../../../../../assets/Images/dress.svg'
import { ReactComponent as transfer }  from '../../../../../../assets/Images/transfert.svg'
import { ReactComponent as toilet }  from '../../../../../../assets/Images/toilet.svg'
import { ReactComponent as continence }  from '../../../../../../assets/Images/continence.svg'
import { ReactComponent as eat }  from '../../../../../../assets/Images/eat.svg'
*/
import wash from '../../../../../../assets/Images/wash.svg'
import dress from '../../../../../../assets/Images/dress.svg'
import transfer from '../../../../../../assets/Images/transfert.svg'
import toilet from '../../../../../../assets/Images/toilet.svg'
import continence from '../../../../../../assets/Images/continence.svg'
import eat from '../../../../../../assets/Images/eat.svg'


const images = [
    wash,
    dress,
    transfer,
    toilet,
    continence,
    eat
]

const tab = [
    'wash',
    'dress',
    'transfer',
    'toilet',
    'continence',
    'eat'
]

var summarise_container = "summcard__container";
var summarise_image = "summcard__img"
var summarise_title_text = "summcard__title--text"

if (window.innerWidth < 450) {
    summarise_container = "summcard__container--mobile";
    summarise_image = "summcard__img--mobile"
    summarise_title_text = "summcard__title--text--mobile"
}
else {
    summarise_container = "summcard__container";
    summarise_image = "summcard__img"
    summarise_title_text = "summcard__title--text"
}

export default class SummariseCard extends Component {


    render() {
        const { translate } = this.props;
        const classCard = 'card__level--' + this.props.score;
        const text = '' + tab[this.props.index] + this.props.score;
        return (
            <div className={summarise_container}>
                <div className="summcard__title">
                    <span className={summarise_title_text}>{this.props.index + 1} - {translate(tab[this.props.index])}</span>
                </div>
                <div className="summcard__img--parent">
                    <img className={summarise_image} src={images[this.props.index]}></img>
                    <div className={classCard}> {this.props.score} </div>
                </div>
            </div>
        )
    }
}
