import React, { Component } from 'react'
import { Col, Row, Container } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './Footer.css'
import { ReactComponent as MyLogo } from "../../assets/SVG/menuburger.svg";
import { changeDisplayFooter } from '../../services/redux/actions/FooterAction'
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { changeScoreKatz } from '../../services/redux/actions/ScoreAction';
import { ReactComponent as Validation } from '../../assets/SVG/validation_vide.svg'
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
class Footer extends Component {
    constructor(props) {
        //le footer est séparé en 2 partie
        //1er partie le bouton orange qui ser à la validation de certaine page (ex : tutoriel)
        // 2 eme partie le bandeau noir avec le nom et le futur menu 
        super(props)
        this.changeFill = ["none", "none", "none", "none", "none", "none"];
        this.tempo = this.props.score;
        this.state={url:window.location.pathname}
    }

    componentDidUpdate(prevprops, prevstate) {
        if (prevprops.score != this.props.score) {
            this.toViewFill()
        }
       
    }

    toViewFill() {
        var length = this.props.score.length
        for (var j = 0; j <= length; j++) {
            if (this.props.score[j] != 0 && this.props.score[j] != "") {
                this.changeFill[j] = "#FE5000"
            }
            else {
                this.changeFill[j] = "none"
            }
        }
        this.forceUpdate()
    }


    render() {
        if(this.state.url!=window.location.pathname){
            this.setState({
                url: window.location.pathname,
            })
        }
        const { t } = this.props;
        var url = window.location.pathname;
        if (this.props.display == true) {

            var path = "/"
            var quota = localStorage.getItem("resultat");
            quota = parseInt(quota)
            
            if(this.props.connect){
                path="/calculatekatz"
            }
            else{
                if(quota >0)
                {path = "/calculatekatz"}
                else{path="/quota"}
            }
           {/* on a mis ce bouton dans ce component car il devait prendre toute la largeur de l'écran*/}
            if (this.state.url==="/confirmSubcription" || this.state.url ==="/tutorial") {
                return (
                    <Link to={{ pathname:path}}>
                        <Container fluid className="footer--2_container">
                            <Row className="footer--2_row">
                                <Col className="footer--2_col" xs={12} md={{size: 12}}>
                                   <button className="footer--2_button">
                                        <Validation className="footer--img--button"></Validation>
                                        <span className="footer--2_txt--button">{t("starting")}</span>
                                    </button>
                                </Col>
                            </Row>
                        </Container>
                    </Link>
                )
            }
            else{
                return (
                    <div className="footer d-flex">
                        <div className="footer__leftContainer">
                            <span className="footer__text">{t("hello")} {this.props.name} </span>
                            {this.displayDeconnect()}
                        </div>
                        <div className="footer__rightContainer d-flex justify-content-end">
                            <svg className="svgIcons" viewBox="0 0 156 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="1" y="1" width="154" height="68" stroke="#FE5000" strokeWidth="2" />
                                <circle cx="58" cy="28" r="4.5" stroke="#FE5000" fill={this.changeFill[0]} />
                                <circle cx="58" cy="43" r="4.5" stroke="#FE5000" fill={this.changeFill[3]} />
                                <circle cx="78" cy="28" r="4.5" stroke="#FE5000" fill={this.changeFill[1]} />
                                <circle cx="78" cy="43" r="4.5" stroke="#FE5000" fill={this.changeFill[4]} />
                                <circle cx="98" cy="28" r="4.5" stroke="#FE5000" fill={this.changeFill[2]} />
                                <circle cx="98" cy="43" r="4.5" stroke="#FE5000" fill={this.changeFill[5]} />
                            </svg>
                        </div>

                    </div>
                    // <Container fluid className="footer">
                    //     <Row className="footer__row1">
                    //         <Col >
                    //             <Container>
                    //                 <Row>
                    //                     <Col xs={8} md={{ size: 8, offset: 1 }}>
                    //                         <div >
                    //                             <span className="footer__text">{t("hello")} {this.props.name} </span>
                    //                             {this.displayDeconnect()}
                    //                         </div>
                    //                     </Col>
                    //                 </Row>
                    //             </Container>
                    //         </Col>
                    //     </Row>
                    //     <div className="footer__button">
                    //         <svg width="156" height="70" viewBox="0 0 156 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                    //             <rect x="1" y="1" width="154" height="68" stroke="#FE5000" strokeWidth="2" />
                    //             <circle cx="58" cy="28" r="4.5" stroke="#FE5000" fill={this.changeFill[0]} />
                    //             <circle cx="58" cy="43" r="4.5" stroke="#FE5000" fill={this.changeFill[3]} />
                    //             <circle cx="78" cy="28" r="4.5" stroke="#FE5000" fill={this.changeFill[1]} />
                    //             <circle cx="78" cy="43" r="4.5" stroke="#FE5000" fill={this.changeFill[4]} />
                    //             <circle cx="98" cy="28" r="4.5" stroke="#FE5000" fill={this.changeFill[2]} />
                    //             <circle cx="98" cy="43" r="4.5" stroke="#FE5000" fill={this.changeFill[5]} />
                    //         </svg>
                    //     </div>
                    // </Container>
                )
            }
        }
        return null;
    }

    displayDeconnect(){
        if(this.props.connect){
            return(<a href='/' className="footer__text--deco" onClick={()=>Cookies.remove('tokken')}>{this.props.t("deconnect")}</a>)
        }
    }
}



function mapStateToProps(store) {
    let display = '';
    if (typeof store.footer.display !== 'undefined') {
        display = store.footer.display;
    }

    return {
        display: display,
        score: store.score.score,
        name: store.name.name,
        connect : store.connect.connect,
    
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeScoreKatz: changeScoreKatz

    }, dispatch)
}


export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Footer);
