import React, { Component } from 'react'
import JsxParser from 'react-jsx-parser';
import { Container, Row, Col } from 'reactstrap';
import './Working.css'
export default class Working extends Component {
    constructor(props) {
        super(props)
        this.nbtoilet = 0;
    }

    switchNbtoilet() {
        if (this.props.value === 'T7') {
            this.nbtoilet = 7
        }
        if (this.props.value === 'T2') {
            this.nbtoilet = 2
        }
    }

    switchReturn() {
         if(this.props.value==='T2' || this.props.value==='T7'){
            return (
                <div className='working__container'>
                    <div className='working__left--text'>
                        <div className='working__youCan'>{this.props.translate("working_youCan")}</div>
                        <div className='working__right--text'>
                            <div className='working__value--text'><span>{this.props.translate(this.props.value)} - </span></div>
                            
                            <div className='working__right'>
                            
                                <span className='working__right--orange'> {this.nbtoilet} {this.props.translate("toilet_per_week")} </span>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else if (this.props.value === 'F_A' || this.props.value === 'F_B' || this.props.value === 'F_C') {
            return (
                <div className='working__container'>
                    <div className='working__left--text'>
                        <div className='working__youCan'>{this.props.translate("working_youCan")}</div>
                        <div className='working__right--text'>
                            <div className='working__value--text'>{this.props.translate(this.props.value)}</div>
                        </div>
                    </div>
                </div>
            )
        }
        else if (this.props.value === 'impossible') {
            return (
                <Container className='working__container'>
                    <Row className='working__left--text'>
                        <Col className='working__right--text'>
                            <div className='working__impossible--text'><JsxParser jsx={this.props.translate("impossible_Katz")} /></div>
                        </Col>
                    </Row>
                </Container>
            )

        }
        else {
            return null
        }
    }

    render() {
        const { translate } = this.props;

        this.switchNbtoilet()

        return (
            this.switchReturn()
        )

    }
}
