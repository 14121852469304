import React, { Component } from 'react'
import "./Using.css"
import { withTranslation } from 'react-i18next';
import JsxParser from 'react-jsx-parser'
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import axios from 'axios'
import Cookies from 'js-cookie';
import Explication from '../../../Explication/Explication';


class Using extends Component {

    changePage() {
        if (localStorage.getItem("resultat") > 0 || this.props.connect) {
            return (
                <Link to="/calculatekatz">
                    <button className="using__button">
                        <span className="using__text--btn"> {this.props.t("yes")} </span>
                    </button>
                </Link>
            )
        }
        else {
            return (
                <Link to="/quota">
                    <button className="using__button">
                        <span className="using__text--btn"> {this.props.t("yes")} </span>
                    </button>
                </Link>
            )
        }
    }



    render() {

        const { t } = this.props;

        return (
            <div className="using__container">

                <span className="using__text"> <JsxParser jsx={t("know_using")} /> </span>
                <span className="using__text using__text--Bold--Orange">HYGIE  </span>
                <span className="using__text using__text--Bold">KATZ  </span>
                <span className="using__text">?</span>

                <div>
                    {this.changePage()}

                    <Link to="/tutorial" >
                        <button className="using__button using__button--margin-right">
                            <span className="using__text--btn"> {t("no")} </span>
                        </button>
                    </Link>


                </div>
            </div >
        )
    }


    //requete get de test pour test jwt sur l'api
    /*request() {
        const token = Cookies.get("tokken");
        const head = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        axios.get('https://localhost:5001/api/test/auth', {
            headers: head
        })
            .then((response) => {
                console.log(response)
            })
            .catch(function (error) {
                console.log(error);
            });

    }*/

}
function mapStateToProps(store) {
    return {
        connect: store.connect.connect
    };
}


export default compose(withTranslation(), connect(mapStateToProps, null))(Using);

