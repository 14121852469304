import React, { Component } from 'react'

import './MainCard.css'

import { ViewDescription } from '../ViewDescription/ViewDescription'


const tmrSlideAppear = 1000; //timer de toute la main card quand elle glisse au debut
const tmrSlideCardDescription = 500; // timer du glissement des description et ensuite de leur text
const tmrSlide = 250;

function asyncAnimCardDescription(index) {
    setTimeout(function () {

        if (index <= this.tabDescriptionRef.length) {

            this.startAnimChildren(index);
            index += 1;
            asyncAnimCardDescription();
        }

    }, tmrSlideAppear);

}

export default class MainCard extends Component {

    constructor(props) {
        super(props);

        this.ref = React.createRef();

        this.marginCard = false;
        this.state = {
            marginCard: false,

        }
    }




    slideCardDescriChangePage(){
        this.ref.current.slideCardDescriChangePage(0);
    }



    render() {

        const { t } = this.props.translate;

        return (

            <div className={this.props.style}>
                <div className="main__title">

                    <span className="main__title--text"> {this.props.index} - {this.props.translate(this.props.cardName)} </span>
                </div>

                <ViewDescription
                    ref={this.ref}
                    translate={this.props.translate}
                    cardName={this.props.cardName}
                    index ={this.props.index}
                    selectPage = {this.props.selectPage}
                    changeScores = {this.props.changeScores}
                    score={this.props.score}
                    index = {this.props.index}>
                </ViewDescription>

            </div>
        )

    }


    popImage() {
        this.imageRef.current.className += " imageRow";
    }
}
