
let baseUrl = "https://api.hygie-katz.care/";

if (process.env.NODE_ENV === "development") {
    baseUrl = "https://localhost:44342/";
} 

export const REGISTRATION_API_URL = baseUrl + "api/registration";
export const RESET_PWD_API_URL    = baseUrl + "api/registration/newPassword";
export const LOGIN_API_URL        = baseUrl + "api/login";
export const CONDITIONS_API_URL   = baseUrl + "api/usingCondition/condition/";