import React from 'react';

import { useTranslation } from "react-i18next";
import 'bootstrap/dist/css/bootstrap.css';
import { createStore } from 'redux';
import rootReducer from './services/redux/reducers';
import { Provider } from 'react-redux';

import './root.css'
import './App.css'
import App2 from './App2';

const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())




export default function App() {
  const { t } = useTranslation();
  return (
    <Provider store={store}>
      <App2/>
    </Provider>
  );
}