import { Select } from '@material-ui/core';
import React, { Component } from 'react'
import './Desoriented.css'
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import JsxParser from 'react-jsx-parser';
import { NativeSelect } from '@material-ui/core';

class Desoriented extends Component {

    render() {
        const { t } = this.props;
        // !! on a pas réussit a donner le style Autrement qu'en Inline pour le type de text !!
        return (
            <div className="desoriented__container">
                <div className="desoriented__message--text">
                    {t("desoriented_message")}
                </div>
                <div className="desoriented__explication--text">
                    <JsxParser jsx={t('desoriented_explication')} />
                </div>

                <Select
                    disableUnderline={true}
                    className="desoriented__dropdown"
                    defaultValue={0}
                    onChange={this.changeValue.bind(this)}
                    style={{ fontFamily: 'Montserrat' }}>
                    <MenuItem value={0} style={{ fontFamily: 'Montserrat' }}>{t("none_desorientation")}</MenuItem>
                    <MenuItem value={1} style={{ fontFamily: 'Montserrat' }}>{t("slight_desorientation")}</MenuItem>
                    <MenuItem value={2} style={{ fontFamily: 'Montserrat' }}>{t("moderate_desorientaton")}</MenuItem>
                    <MenuItem value={3} style={{ fontFamily: 'Montserrat' }}>{t("severe_desorientation")}</MenuItem>
                </Select>
            </div>
        )
    }

    changeValue(value) {
        this.props.changeDesorientation(value.target.value)
    }
}

export default withTranslation()(Desoriented)