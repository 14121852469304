import React, { Component } from 'react'
import { Button, Col, Row, Container } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './ConfirmSubscription.css'
import { ReactComponent as ArrowReturn } from '../../assets/SVG/arrow-return.svg'
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { changeDisplayFooter } from '../../services/redux/actions/FooterAction';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import * as constants from '../constants.js';

class ConfirmSubscription extends Component {
    // page ou on est redirigé si la demande d'inscription a été validée par l'API + possibilité de renvoy de mail
    constructor(props) {
        super(props)

        this.state = {
            status: "",
            display: true
        }

    }

    postRequest(bool) {
        let url = constants.REGISTRATION_API_URL;

        axios.post(url, {
            Firstname: this.props.location.state.name,
            Email: this.props.location.state.mail,
            Password: this.props.location.state.password,
            SendEmailAgain: bool,
            Language: localStorage.getItem("i18nextLng"),
            headers: {"Access-Control-Allow-Origin": "*"}
            
        })
            .then(res => {
                var val = res.data;
                this.onChangeText(val);
            })
    }

    componentDidMount() { this.props.changeDisplayFooter(true) }

    render() {
        const { t } = this.props;
        return (
            <Container>
                <Row className="mt-4">
                    <Col xs={{ size: 12 }} sm={{ size: 11, offset: 1 }} md={{ size: 11, offset: 1 }} lg={{ size: 11, offset: 1 }} xl={{ size: 11, offset: 1 }}>
                        {/* <NavLink to={'/'}><ArrowReturn /></NavLink> */}
                        <h1 className="ConfirmSub__Titre1">{t("welcom")}</h1>
                        <h2 className="ConfirmSub__Titre2">{this.props.location.state.name}</h2>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col xs={{ size: 12 }} sm={{ size: 11, offset: 1 }} md={{ size: 6, offset: 1 }} lg={{ size: 5, offset: 1 }} xl={{ size: 5, offset: 1 }}>
                        <p className="ConfirmSub__p ConfirmSub__textBold">{t("thx_Subscribtion")}</p>
                        <p className="ConfirmSub__p">{t("Check_mail")}</p>
                        <p className="ConfirmSub__p">{t("use_hygieKatz_subscribtion")}</p>
                        {this.onDisplayButton()}
                    </Col>
                </Row>
                <Row className="ConfirmSub__margin--1row">
                    <Col xs={{ size: 12 }} sm={{ size: 11, offset: 1 }} md={{ size: 6, offset: 1 }} lg={{ size: 5, offset: 1 }} xl={{ size: 5, offset: 1 }}>
                        {t(this.state.status)}
                    </Col>
                </Row>
            </Container>
        )
    }

    onChangeText(value) {
        if (value == "resendMail") {
            this.setState({ status: "resend_mail", display: false })
        }
    }

    onDisplayButton() {
        if (this.state.display) {
            return (
                <button className="ConfirmSub__button" onClick={() => this.postRequest(true)}><span className="ConfirmSub__button--text">{this.props.t("reSend_mail")}</span></button>
            )
        }
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeDisplayFooter: changeDisplayFooter,

    }, dispatch)
}

export default compose(withTranslation(), connect(null, mapDispatchToProps))(ConfirmSubscription);
