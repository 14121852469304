import React, { Component } from 'react';
import './WrapperButton.css';
import { withTranslation } from 'react-i18next';

class WrapperButton extends Component {
    //BoutonOrange avec bordure noir, text dynamique
    render() {
        const { t } = this.props;
        return (

            <button className="animated-button"
                type="button"
                onClick={() => this.props.onPressSubmit()}>
                {/* <span className="wrapper--button__wrapper"></span> */}
                {/* <span className="wrapper--button__text">{this.props.t(this.props.text)}</span> */}
                {this.props.t(this.props.text)}
            </button>

        )
    }

    //permet de gerer si le bouton doit etre actif 
    onChangeButton() {
        if (this.props.disabled) {
            return (
                <button className="wrapper--button__button"
                    type="button"
                    onClick={() => this.props.onPressSubmit()}>
                    <span className="wrapper--button__wrapper"></span>
                    <span className="wrapper--button__text">{this.props.t(this.props.text)}</span>
                </button>
            )
        }
        else {
            return (
                <button className="wrapper--button__button"
                    type="button"
                    disabled
                    onClick={() => this.props.onPressSubmit()}>
                    <span className="wrapper--button__wrapper"></span>
                    <span className="wrapper--button__text">{this.props.t(this.props.text)}</span>
                </button>
            )
        }
    }
}

export default withTranslation()(WrapperButton)