import { CONNECT } from '../actions/ConnectAction';

const initialState = {
    connect: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CONNECT:
            return { ...state, connect: action.payload };
        default:
            return state
    }
}
