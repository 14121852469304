import { CHANGE_NAME } from '../actions/NameAction';

const initialState = {
    name: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CHANGE_NAME:
            return { ...state, name: action.payload };
        default:
            return state
    }
}