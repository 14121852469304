import React, { Component } from 'react'
import { ReactComponent as Valid } from '../../assets/SVG/ValidSVG.svg'
import { ReactComponent as Invalid } from '../../assets/SVG/InvalidSVG.svg'
import './Input.css'

export default class Input extends Component {
    constructor(props) {
        super(props)
    }

    //on prend l'image qui correspond à l'etat de l'input (donné par le parent)
    chooseImage() {
        if (this.props.style === "inputValid") {
            return (
                <Valid className="input__image"></Valid>
            )
        }
        else if (this.props.style === "inputInvalid") {
            return (
                <Invalid className="input__image"></Invalid>
            )
        }
        else {
            return null;
        }
    }

    render() {
        const { t } = this.props;
        var inputStyle = "input__inputStyle";
        if (this.props.style === "inputValid") {
            inputStyle += " input__inputGreen"
        }
        else if (this.props.style === "inputInvalid") {
            inputStyle += " input__inputRed"
        }
        else {
            inputStyle += " input__inputNormal"
        }
        return (
            <div className="input__container">
                <div className="input__nom">
                    <label>{this.props.inputName}</label>
                    {this.props.children}
                </div>
                <div>
                    <input
                        className={inputStyle}
                        placeholder={this.props.placeholder}
                        required
                        onChange={(e)=>this.props.onChange(e.target.value)} 
                        type={this.props.type}/>
                        {this.chooseImage()}
                </div>
                
            </div>
        )
    }
}
