import React, { Component } from 'react'
import './Connect.css'
import { Button, Col, Row, Container } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';
import Input from '../../components/Input/Input'
import { BookmarkBorder } from '@material-ui/icons';
import { ReactComponent as ArrowReturn } from '../../assets/SVG/arrow-return.svg'
import WrapperButton from '../../components/WrapperButton/WrapperButton';
import { withTranslation } from 'react-i18next';
import { Link, NavLink, Redirect } from 'react-router-dom';
import axios from 'axios'
import sha256 from 'js-sha256'
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { changeConnect } from '../../services/redux/actions/ConnectAction';
import { changeName } from '../../services/redux/actions/NameAction';
import { changeDisplayFooter } from '../../services/redux/actions/FooterAction'
import Cookies from 'js-cookie';
import * as constants from '../constants.js';

const inputStyle = "inputNone";
const inputStyleValide = "inputValid";
const inputStyleInvalide = "inputInvalid";

const validEmailRegex = RegExp(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i);
const validPasswordRegex = RegExp(/^(?=.*\d)(?=.*[a-x])(?=.*[A-Za-z\d]).{8,50}$/);

class Connect extends Component {
    constructor(props) {
        super(props);
        this.index = 0
        this.state = {
            user: '',
            response: 0,
            name: '',
            mailVerif: '',
            message: '',
            mail: '',
            password: '',

            inputStyle: [inputStyle, inputStyle]

        }
        this.props.changeDisplayFooter(false);
    }

    componentDidMount() {
        document.title = "Hygie Katz - Connect"
    }

    defaultReturn() {
        var url = this.props.location.state;
        if (url != undefined) {
            return (<NavLink to={this.props.location.state.url}><ArrowReturn /></NavLink>)
        }
        else {
            return (<NavLink to={'/'}><ArrowReturn /></NavLink>)
        }
    }

    //en fonction de la rep de l'api on affiche un message
    onConnect() {
        if (this.state.response == 200) {
            this.props.changeConnect(true);
            this.props.changeName(this.state.name)
            return (<Redirect to={{
                pathname: "/",

            }} />)
        }

        else if (this.state.response == 204) {
            return (<div> {this.props.t("wrongMailOrPassword")}</div>)
        }
        else if (this.state.response == 210 && this.state.message != "resend_mail") {
            return (
                <div>
                    <div className="connect__btn--ressend">
                        {this.props.t("no_verif_mail")}
                    </div>

                    <button className="ConfirmSub__button"
                        onClick={() => this.postRequest(true)}>
                        <span className="ConfirmSub__button--text">{this.props.t("reSend_mail")}</span>
                    </button>
                </div>

            )
        }
        else {
            return null;
        }
    }

    render() {

        var connectTitle = "connect__title";
        var connect = "connect__inputs"
        var connectText = "connect__title--Container"
        const { t } = this.props;

        return (
            <Container className="mt-5">
                <Row>
                    <Col className="d-flex align-items-center justify-content-center" xs={{size: 2}} md={{size:1, offset : 1}} >
                        {this.defaultReturn()}
                    </Col>
                    <Col className={connectText} xs={{ size: 10 }} md={{ size: 9}}>
                        <div className="connect__title--container">
                            
                            <h1 className={connectTitle}>{t("login")}</h1>
                        </div>
                        <NavLink
                            to={{
                                pathname: '/Registration',
                                state: { url: '/' }
                            }}>
                            <div className="connect__inscription">{t("registration")}</div>
                        </NavLink>
                    </Col>
                </Row>
                <Row className={connect}>
                    <Col xs={{ size: 10 }}  md={{ size: 4, offset: 1 }} >
                        <Input
                            value={this.state.mail}
                            inputName={t("Email")}
                            placeholder={t("VHenderson@mail.com")}
                            onChange={this.handleChangeMail.bind(this)}
                            style={this.state.inputStyle[0]}
                            type={"text"}>
                        </Input>
                        <Input
                            value={this.state.confirmPassword}
                            inputName={t("password")}
                            placeholder={t("your_password")}
                            onChange={this.handleChangePassword.bind(this)}
                            style={this.state.inputStyle[1]}
                            type={"password"}
                        />
                        <Link to="/forgotPassword">
                            <div className="connect__place--mdpForget">
                                <span className="connect__mdpForget">{t("forgotten_password")}</span>
                            </div>
                        </Link>
                    </Col>
                    <Col xs={{ size: 11 }} sm={{ size: 11 }} md={{ size: 11, offset: 1 }} xl={{ size: 11, offset: 1 }} className="registration__button">
                        <div>
                            {this.onConnect()}
                            <div className="connect__btn--ressend">
                                {t(this.state.message)}
                            </div>
                            <WrapperButton
                                text={"connect"}
                                onPressSubmit={() => this.getUser()} />
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }

    getUser() {

        let url = constants.LOGIN_API_URL;

        axios.post(url, {
            Mail: this.state.mail,
            Password: this.state.password,
            headers: {"Access-Control-Allow-Origin": "*"}

        })
            .then(res => {
                if (res.data.user.verifMail) {
                    this.setState({ response: res.request.status, name: res.data.user.name, mailVerif: res.data.user.mail })
                    Cookies.set("tokken", res.data.token, { path: '' });
                }
                else { this.setState({ response: 210, name: res.data.user.name, mailVerif: res.data.user.mail }) }

                
            })
            .catch(res => {
                this.setState({ response: 204 })
            });

    }

    postRequest(bool) {

        let url = constants.REGISTRATION_API_URL;

        axios.post(url, {
            Firstname: this.state.name,
            Email: this.state.mailVerif,
            SendEmailAgain: bool,
            Language: localStorage.getItem("i18nextLng"),
            headers: {"Access-Control-Allow-Origin": "*"} 
        })
            .then(res => {
                var val = res.data;
                this.messageRessend(val);
            })
            .catch(console.log('erreur'))
    }

    messageRessend(value) {
        if (value == 'resendMail') { this.setState({ message: "resend_mail" }) }
    }


    handleChangePassword(value) {
        this.setState({ password: value });
        var style = this.state.inputStyle;

        if (validPasswordRegex.test(value)) {
            style[1] = inputStyleValide;
            this.setState({
                inputStyle: style
            })
            this.passwordValid = true
        }
        else {
            style[1] = inputStyleInvalide;
            this.setState({
                inputStyle: style
            })
            this.passwordValid = false;
        }

        if (value.length === 0) {
            style[1] = inputStyle;
            this.setState({
                inputStyle: style
            })
        }
    }

    handleChangeMail(value) {
        var style = this.state.inputStyle;
        this.setState({ mail: value })
        validEmailRegex.test(value)

        if (validEmailRegex.test(value)) {
            style[0] = inputStyleValide;
            this.setState({
                inputStyle: style
            })
            this.emailValid = true;
        }
        else {
            style[0] = inputStyleInvalide;
            this.setState({
                inputStyle: style
            })
            this.emailValid = false
        }

        if (this.state.mail.length === 0) {
            style[0] = inputStyle;
            this.setState({
                inputStyle: style
            })
        }

    }
}
function mapStateToProps(store) {
    let display = '';
    if (typeof store.footer.display !== 'undefined') {
        display = store.footer.display;
    }

    return {
        display: display,
        score: store.score.score,
        connect: store.connect.connect,
        name: store.name.name
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeConnect: changeConnect,
        changeName: changeName,
        changeDisplayFooter: changeDisplayFooter,
    }, dispatch)
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Connect);
