
import React, { Component } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { ReactComponent as ArrowReturn } from '../../assets/SVG/arrow-return.svg'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import './UsingCondition.css'
import Iframe from 'react-iframe';
import { NavLink } from 'react-router-dom';
import * as constants from '../constants.js';

class UsingCondition extends Component {

    constructor(props){
        super(props)
        this.state={
            heightFrame : 50,
            url : this.getURL()+localStorage.getItem("i18nextLng"),
            language:localStorage.getItem("i18nextLng")
        }
    }

    getURL(){
        let url = constants.CONDITIONS_API_URL;//"";
        // if (process.env.NODE_ENV === "development") {
        //     url = 'https://localhost:5001/api/usingCondition/condition/'
        // }else
        // {
        //     url = 'https://api.hygie-katz.care/api/usingCondition/condition/'
        // }
        console.log(url)
        return url;
    }
    
    defaultReturn() {
        var url = this.props.location.state;

        if (url != undefined) {
            return (<NavLink to={this.props.location.state.url}><ArrowReturn /></NavLink>)
        }
        else {
            return (<NavLink to={'/'}><ArrowReturn /></NavLink>)
        }
    }

    componentDidUpdate(prevprops){
        console.log("update")
        if(this.state.language != localStorage.getItem("i18nextLng"))
        {
    
            this.setState({
                language : localStorage.getItem("i18nextLng"),
                url : this.getURL()+localStorage.getItem("i18nextLng")
            })
        }
    }

    render() {
        /*if(this.state.language != localStorage.getItem("i18nextLng"))
        {
            let url = "";
            if (process.env.NODE_ENV === "development") {
                url = 'https://localhost:5001/api/usingCondition/condition/'
            }else
            {
                url = 'https://api.hygie-katz.care/api/usingCondition/condition/'
            }
    
            this.setState({
                language : localStorage.getItem("i18nextLng"),
                url : ""+localStorage.getItem("i18nextLng")
            })
        }*/

        const { t } = this.props;
        var styleTitle = "using__title";
        if (window.innerWidth < 450) {
            styleTitle = "using__title--mobile";
        }
        // https://localhost:5001/api/usingCondition
        return (
            <Container id="cont">
                <Row>
                    <Col xs={{ size: 12 }} sm={{ size: 11, offset: 1 }} md={{ size: 10, offset: 1 }} lg={{ size: 10, offset: 1 }} xl={{ size: 10, offset: 1 }}>
                        <div className="using__header">

                            {this.defaultReturn()}
                            <h1 className={styleTitle}>
                                {t("usingCondition")}
                            </h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ size: 12 }} sm={{ size: 11, offset: 1 }} md={{ size: 11, offset: 1 }} lg={{ size: 11, offset: 1 }} xl={{ size: 11, offset: 1 }}>
                        <Iframe url={this.state.url}
                            className="using__iframe"
                            display="initial"
                            position="relative"
                            scrolling="yes"
                            >
                        </Iframe>
                    </Col>
                </Row>
            </Container>
        )
    }
}


export default compose(withTranslation(), connect(null, null))(UsingCondition);