import React, { Component } from 'react'
import { NavLink, Redirect } from 'react-router-dom'
import { Col, Container, Form, Row } from 'reactstrap'
import Input from '../../components/Input/Input'
import './Registration.css'
import { ReactComponent as ArrowReturn } from '../../assets/SVG/arrow-return.svg'
import WrapperButton from '../../components/WrapperButton/WrapperButton';
import { withTranslation } from 'react-i18next';
import axios from 'axios'
import sha256 from 'js-sha256'
import { changeDisplayFooter } from '../../services/redux/actions/FooterAction'
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { ReactComponent as CheckBox } from '../../assets/SVG/Checkbox.svg';
import { ReactComponent as CheckBoxValid } from '../../assets/SVG/CheckBoxValid.svg';
import * as constants from '../constants.js';


const inputStyle = "inputNone";
const inputStyleValide = "inputValid";
const inputStyleInvalide = "inputInvalid";
const validEmailRegex = RegExp(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i);
const validPasswordRegex = RegExp(/^(?=.*\d)(?=.*[a-x])(?=.*[A-Za-z\d]).{8,50}$/);
const phoneMaxLength = 20;
const phoneMinLength = 9;
const phoneRegex = RegExp(/^[0-9]+$/);


class Registration extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: "",
            password: "",
            confirmPassword: "",
            mail: "",
            phone : "",
            responseDB: "",
            displayMessage: "",
            usingConditions: false,
            newsletter: false,
            inputStyle: [inputStyle, inputStyle, inputStyle, inputStyle, inputStyle],
            verif: ""
        }

        this.emailValid = false;
        this.phoneValid = false;
        this.passwordValid = false;
        this.passwordCheck = false;
        this.onBlur = false;
        this.button = false;
        this.props.changeDisplayFooter(false);
    }





    componentDidMount() {
        document.title = "Hygie Katz - Registration"

    }

    componentDidUpdate(prevProps, prevState) {

        var style = this.state.inputStyle;
        if (this.state.password != prevState.password && this.state.confirmPassword.length != 0) {

            if (this.state.password === this.state.confirmPassword) {
                style[3] = inputStyleValide;
                this.setState({
                    inputStyle: style
                })
                this.passwordCheck = true
            }
            else {
                style[3] = inputStyleInvalide;
                this.setState({
                    inputStyle: style
                })
                this.passwordCheck = false;
            }
        }
    }

    defaultReturn() {
        var url = this.props.location.state;

        if (url != undefined) {
            return (<NavLink to={this.props.location.state.url}><ArrowReturn /></NavLink>)
        }
        else {
            return (<NavLink to={'/'}><ArrowReturn /></NavLink>)
        }
    }

    render() {
        var registration = "registration__input"
        var registrationText = "registration__title--container"
        var registrationTitle = "connect__title";

        const { t } = this.props;

        if (this.emailValid && this.passwordCheck && this.passwordValid) { this.button = true }
        else { this.button = false }

        return (
            <Container className='mt-5'>
                <Row>
                    <Col className="d-flex align-items-center justify-content-center" xs={{size: 2}} md={{size:1, offset : 1}}>
                        {this.defaultReturn()}
                    </Col>
                    <Col className={registrationText} xs={{ size: 10 }} md={{ size: 9}}>
                        <div className="connect__title--container">
                            <h1 className={registrationTitle}>{t("registration")}</h1>
                        </div>
                        <NavLink
                            to={{
                                pathname: '/connect',
                                state: { url: '/' }
                            }}>
                            <div className="registration__login">{t("login")}</div>
                        </NavLink>
                    </Col>
                </Row>


                <Row className={registration}>
                    <Col className={"registration__inputColumn"} xs={{ size: 10, order: 1 }} md={{ size: 4, offset: 1, order: 1 }}>
                        <Input
                            value={this.state.name}
                            inputName={t("your_name")}
                            placeholder={t("Virginie")}
                            onChange={this.handleChangeName.bind(this)}
                            style={this.state.inputStyle[0]}
                            type={"text"}>
                        </Input>
                    </Col>
                    <Col className={"registration__inputColumn registration__mrgTop"} xs={{ size: 10, order : 3 }} md={{ size: 4, offset: 0, order: 2 }}>
                        <Input
                            value={this.state.password}
                            inputName={t("password")}
                            placeholder={t("your_password")}
                            onChange={this.handleChangePassword.bind(this)}
                            style={this.state.inputStyle[1]}
                            type={"password"}>
                            <div className="registration__marginExplanation">
                                <p className={"registration__textGrey"}> • {t("MinimumCheractere")}</p>
                                <p className={"registration__textGrey"}> • {t("MinimumNumber")}</p>
                            </div>
                        </Input>
                    </Col>



                    <Col className={"registration__inputColumn registration__mrgTop"} xs={{ size: 10, order : 2 }}  md={{ size: 4, offset: 1, order: 3 }}>

                        <Input
                            value={this.state.mail}
                            inputName={t("Email")}
                            placeholder={t("VHenderson@mail.com")}
                            onChange={this.handleChangeMail.bind(this)}
                            style={this.state.inputStyle[2]}
                            type={"text"}>
                        </Input>

                    </Col>
                    <Col className={"registration__inputColumn registration__mrgTop"} xs={{ size: 10, order : 4 }} md={{ size: 4, offset: 0, order: 4 }}>

                        <Input
                            value={this.state.confirmPassword}
                            inputName={t("repeat_password")}
                            placeholder={t("your_password")}
                            onChange={this.handleChangeConfirmPassword.bind(this)}
                            style={this.state.inputStyle[3]}
                            type={"password"}
                        >
                            <div>
                                <p className={"registration__textGrey"}>{t("repeat_password_message")}</p>
                            </div>
                        </Input>
                    </Col>
                </Row>

                <Row>
                    <Col className={"registration__inputColumn registration__mrgTop"} xs={{ size: 10, order : 2 }}  md={{ size: 4, offset: 1, order: 3 }}>

                        <Input
                            value={this.state.phone}
                            inputName={t("phone")}
                            placeholder={t("012345678")}
                            onChange={this.handleChangePhone.bind(this)}
                            style={this.state.inputStyle[4]}
                            type={"text"}>
                        </Input>

                    </Col>
                </Row>

                <Row className="registration__margin--InputRow">
                    <Col xs={{ size: 10 }} md={{ size: 11, offset: 1 }}>
                        <div className="form-group form-check">
                            <input type="checkbox" className="form-check-input" id="terms" onClick={this.handleChangeCondition.bind(this)} />
                            <label className="form-check-label" htmlFor="terms"><span className="registration__CheckBox--text">{t("accepTo")} </span>
                                <NavLink to={{
                                    pathname: '/Condition',
                                    state: { url: window.location.pathname }
                                }} className="registration__CheckBox--text--orange"><span className="registration__CheckBox--text--orange">{t("usingCondition")}</span></NavLink>
                            </label>
                        </div>
                        <div className="form-group form-check">
                            <input type="checkbox" className="form-check-input" id="newsletter" onClick={this.handleChangeNewsletter.bind(this)} />
                            <label className="form-check-label" htmlFor="newsletter">
                                <p className="registration__CheckBox--text">{t("newsletter")}</p>
                            </label>
                        </div>
                    </Col>
                </Row>

                <Row className="registration__button">
                    <Col xs={{ size: 10 }} md={{ size: 11, offset: 1 }}>
                        <div>
                            <WrapperButton
                                text={"registration"}
                                onPressSubmit={() => this.onPressSubmit(false)}
                                disabled={this.button} />
                        </div>
                        <div className="registration__inputDiv registration__msg">
                            {t(this.state.displayMessage)}
                        </div>
                        <div className="registration__inputDiv registration__mrgTop">
                            {this.ressend()}
                        </div>


                    </Col>
                </Row>
            </Container>
        )
    }
    handleChangeCondition() {
        if (this.state.usingConditions) {
            this.setState({ usingConditions: false })
        }
        else { this.setState({ usingConditions: true }) }
    }
    handleChangeNewsletter() {
        if (this.state.newsletter) {
            this.setState({ newsletter: false })
        }
        else { this.setState({ newsletter: true }) }
    }

    switchSvg(checked) {
        if (checked) {
            return (<CheckBoxValid />)
        }
        else { return (<CheckBox />) }
    }

    handleChangeName(value) {

        this.setState({ name: value });
    }

    handleChangePassword(value) {
        this.setState({ password: value });
        var style = this.state.inputStyle;

        if (validPasswordRegex.test(value)) {
            style[1] = inputStyleValide;
            this.setState({
                inputStyle: style
            })
            this.passwordValid = true
        }
        else {
            style[1] = inputStyleInvalide;
            this.setState({
                inputStyle: style
            })
            this.passwordValid = false;
        }

        if (value.length === 0) {
            style[1] = inputStyle;
            this.setState({
                inputStyle: style
            })
        }
    }

    handleChangeConfirmPassword(value) {
        var style = this.state.inputStyle;

        if (this.state.password === value) {
            style[3] = inputStyleValide;
            this.setState({
                inputStyle: style
            })
            this.passwordCheck = true
        }
        else {
            style[3] = inputStyleInvalide;
            this.setState({
                inputStyle: style
            })
            this.passwordCheck = false;
        }

        if (value.length === 0) {
            style[3] = inputStyle;
            this.setState({
                inputStyle: style
            })
        }

        this.setState({ confirmPassword: value });
    }

    handleChangeMail(value) {
        var style = this.state.inputStyle;
        this.setState({ mail: value })
        validEmailRegex.test(value)

        if (validEmailRegex.test(value)) {
            style[2] = inputStyleValide;
            this.setState({
                inputStyle: style
            })
            this.emailValid = true;
        }
        else {
            style[2] = inputStyleInvalide;
            this.setState({
                inputStyle: style
            })
            this.emailValid = false
        }

        if (this.state.mail.length === 0) {
            style[2] = inputStyle;
            this.setState({
                inputStyle: style
            })
        }

    }

    handleChangePhone(value) {
        var style = this.state.inputStyle;
        this.setState({ phone: value });

        if (value.trim().length == 0 
            || (
                value.trim().length >= phoneMinLength 
                && value.trim().length <= phoneMaxLength
                && phoneRegex.test(value.trim())
                )
            ) {
                style[4] = inputStyleValide;
            
                this.phoneValid = true
        } else {
            

            style[4] = inputStyleInvalide;
            
            this.phoneValid = false
        }

        this.setState({
            inputStyle: style
        })
    }

    onPressSubmit(bool) {
        console.log("passe");
        if (this.emailValid && this.passwordCheck && this.passwordValid) {
            if (this.state.usingConditions) {
                var val;

                let url = constants.REGISTRATION_API_URL;

                axios.post(url, {
                    Firstname: this.state.name,
                    Email: this.state.mail.toString(),
                    Password: this.state.password,
                    Phone: this.state.phone,
                    SendEmailAgain: bool,
                    Language: localStorage.getItem("i18nextLng"),
                    AcceptNewsletter: this.state.newsletter,
                    AcceptCondition: this.state.usingConditions,
                    headers: {"Access-Control-Allow-Origin": "*"}
                })
                    .then(res => {
                        val = res.data.status;
                        console.log(res);
                        this.messageAlert(val);
                        this.emailValid = false;
                        this.passwordValid = false;
                        this.passwordCheck = false;
                        this.setState({ verif: val, password: this.state.password })
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
            else {
                this.messageAlert("conditionUnchecked");
            }
        }
        else {
            this.setState({ password: '', confirmPassword: '' })
        }

    }

    postRequest(bool) {
        var val;

        let url = constants.REGISTRATION_API_URL;

        axios.post(url, {
            Firstname: this.state.name,
            Email: this.state.mail.toString(),
            Password: this.state.password,
            Phone: this.state.phone,
            SendEmailAgain: bool,
            Language: localStorage.getItem("i18nextLng"),
            headers: {"Access-Control-Allow-Origin": "*"}
        })
            .then(res => {

                val = res.data;

                this.messageAlert(val);
                this.emailValid = false;
                this.passwordValid = false;
                this.passwordCheck = false;
                this.setState({ verif: val })

            })
            .catch(console.log('erreur'))
    }

    messageAlert(val) {

        var message = "";

        switch (val) {
            case 'mailNonVerif': message = "no_verif_mail"
                break;
            case 'mailExist': message = 'mail_exist'
                break;
            case 'resendMail': message = "resend_mail"
                break;
            case 'conditionUnchecked': message = "conditionUnchecked"
                break;

        }

        this.setState({ displayMessage: message })
    }


    ressend() {
        if (this.state.verif == 'mailNonVerif') {
            return (<button className="ConfirmSub__button" onClick={() => this.postRequest(true)}><span className="ConfirmSub__button--text">{this.props.t("reSend_mail")}</span></button>)
        }

        if (this.state.verif == 'accountCreated') {
            return (
                <Redirect to={{
                    pathname: "/confirmSubcription",
                    state: { mail: this.state.mail, password: this.state.password, name: this.state.name }
                }} />
            )
        }


    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeDisplayFooter: changeDisplayFooter,


    }, dispatch)
}

export default compose(withTranslation(), connect(null, mapDispatchToProps))(Registration);