import { combineReducers } from "redux"
import ConnectReducer from "./ConnectReducer";
import FooterReducer from './FooterReducer'
import NameReducer from "./NameReducer";
import ScoreReducer from "./ScoreReducer";

const rootReducer = combineReducers({
    footer: FooterReducer,
    score : ScoreReducer,
    connect : ConnectReducer,
    name : NameReducer
})

export default rootReducer;