import React, { Component } from 'react'
import { Col, Container, Row } from 'reactstrap'
import MainCard from '../MainCard/MainCard'
import SelectBar from '../SelectBar/SelectBar'
import SideCard from '../SideCard/SideCard'

import './Cards.css'

// changement de style
const tmrSlideCard = 250;
// fade 
const fade0to1 = " fadeIn750"
// animaletion de translation des carte droite
const rcSlideLeftLeft = " rcSlideLeftLeft";
const rcSlideLeftRight = " rcSlideLeftRight";
const rcSlideRight = " rcSlideRight";
const rcSlideLeft = " rcSlideLeft";
// animaletion de translation des carte gauche
const lcSlideRightRight = " lcSlideRightRight";
const lcSlideRight = " lcSlideRight";
const lcSlideLeft = " lcSlideLeft";
// nom des classname des carde de coté
const styleSideRightCard = " side--card__right--card";
const styleSideRightCard2 = " side--card__right--card--2";
const styleSideLeftCard = " side--card__left--card";
const styleSideLeftCard2 = " side--card__left--card--2";
// nom des classname des text de carte de coté
const styleSCTextRight = " side--card__txt--right--Card";
const styleSCTextLeft = " side--card__txt--left--Card";
// nom des classname qui permette de caché ou moutrer un element
const styleHidden = " side--card__hidde";
const styleVisible = " side--card__visible";
// nom des classname qui permette de mettre la carte en absolute pour la sortir du flux
const styleLeftAbsolute = " side--card__left--card--absolute";
const styleRightAbsolute = " side--card__right--card--absolute";
// classname de la mainCard
const mcStyle = " main__container";
//end style
export default class Cards extends Component {

    constructor(props) {
        super(props);

        this.changePageAnim = ""
        this.start = ""
        this.end = ""

        this.refSideCard = React.createRef();
        this.refMain = React.createRef();

        this.state = {

            SideCardStyle: this.startPage(),

            TabSideCardText: [styleSCTextLeft, styleSCTextLeft,
                styleSCTextRight, styleSCTextRight],

            mainCardStyle: mcStyle + rcSlideRight,
        }

    }

    componentDidUpdate(prevprops) {
        if (prevprops.cardIndex != this.props.cardIndex) {

            this.changePage(this.props.cardIndex);
        }
    }

    componentDidMount() {
        var num = parseInt(this.props.cardIndex)
        if (num === 0) {
            this.onMainCardAnimEnd();
        }
        else {
            this.changePage(num)
        }


    }
    onEndSlide() {
        var slide = this.end-this.start
        if(this.end!=""){
            if(slide>=-250 && slide<=250){
                if (this.end < this.start) {
                    if (this.props.cardIndex >= 0) {
                        this.props.selectPage(this.props.cardIndex + 1)
                        this.end=0;
                        this.start=0;
                    }
        
        
                }
                else {
                    if (this.props.cardIndex >= 1&& this.props.cardIndex <= 5) {
                        this.props.selectPage(this.props.cardIndex - 1)
                        this.end=0;
                        this.start=0;
                    }
                }
            }
       
        }
   
    }


    render() {

        var cardClass = "cards__container--cards fadeIn";
        return (

            <div className="cards__container"
               >


                <div className={cardClass}
                 onTouchStart={(e) => (this.start = e.targetTouches[0].clientX)}
                 onTouchMove={(e) => (this.end = (e.targetTouches[0].clientX))}
                 onTouchEnd={() => this.onEndSlide()}>
                    <SideCard

                        index={this.props.cardIndex - 1}
                        startChangeCardAnim={() => this.changePage(this.props.cardIndex - 2)}
                        cardName={this.props.tabCard[this.props.cardIndex - 2]}
                        translate={this.props.translate}

                        style={this.state.SideCardStyle[0]}
                        styleText={this.state.TabSideCardText[0]}
                    ></SideCard>
                    <SideCard

                        index={this.props.cardIndex}
                        startChangeCardAnim={() => this.changePage(this.props.cardIndex - 1)}
                        cardName={this.props.tabCard[this.props.cardIndex - 1]}
                        translate={this.props.translate}

                        style={this.state.SideCardStyle[1]}
                        styleText={this.state.TabSideCardText[1]}

                    ></SideCard>
                    <MainCard
                        ref={this.refMain}
                        translate={this.props.translate}
                        cardName={this.props.tabCard[this.props.cardIndex]}
                        index={this.props.cardIndex + 1}
                        selectPage={() => this.changePage(this.props.cardIndex + 1)}
                        changeScores={this.props.changeScores}
                        score={this.props.score}
                        style={this.state.mainCardStyle}
                    >
                    </MainCard>
                    <SideCard

                        translate={this.props.translate}
                        cardName={this.props.tabCard[this.props.cardIndex + 1]}
                        index={this.props.cardIndex + 2}
                        startChangeCardAnim={() => this.changePage(this.props.cardIndex + 1)}

                        style={this.state.SideCardStyle[2]}
                        styleText={this.state.TabSideCardText[2]}>
                    </SideCard>
                    <SideCard

                        translate={this.props.translate}
                        cardName={this.props.tabCard[this.props.cardIndex + 2]}
                        index={this.props.cardIndex + 3}
                        startChangeCardAnim={() => this.changePage(this.props.cardIndex + 2)}

                        style={this.state.SideCardStyle[3]}
                        styleText={this.state.TabSideCardText[3]}
                    ></SideCard>

                </div>

                <div className="cards__selectbar ">
                    <SelectBar
                        index={this.props.cardIndex}
                        selectPage={this.props.selectPage.bind(this)}></SelectBar>
                </div>

            </div>

        )
    }

    //Gestion du changement d'index pour contrôler l'ordre des animations
    changePage(index) {
        this.props.selectPage(index);
        switch (index) {
            case 0:
                this.changeIndexTO0();
                break;

            case 1:
            case 2:
            case 3:
            case 4:
                this.changeIndex1To2();
                break;

            case 5:
                this.changeIndexTo5();
                break;

            default:
                break;
        }
    }

    startPage() {
        var tabStyle = new Array();
        switch (this.props.cardIndex) {
            case 0:
                tabStyle = [styleSideLeftCard2 + styleHidden + styleLeftAbsolute,
                    styleSideLeftCard + styleLeftAbsolute + styleHidden,
                    styleSideRightCard + styleHidden,
                    styleSideRightCard2 + styleHidden]
                break;
            case 5:
                tabStyle = [styleSideLeftCard2 + styleHidden + styleLeftAbsolute,
                    styleSideLeftCard + styleLeftAbsolute + styleHidden,
                    styleSideRightCard + styleHidden,
                    styleSideRightCard2 + styleHidden]
                break;
            default:
                tabStyle =
                     [styleSideLeftCard2 + styleHidden + styleLeftAbsolute,
                        styleSideLeftCard + styleVisible + lcSlideLeft,
                        styleVisible + styleSideRightCard + rcSlideRight,
                        styleRightAbsolute + styleHidden + styleRightAbsolute]
                
                break;
        }
        return tabStyle;
    }
    //#region Page Appear
    /*
       [0] - carte extreme gauche
       [1] - carte gauche
       [2] - carte droite
       [3] - carte extreme droite
       quand il ya un if ( ... .include".." )c est pour savoir
        si je vien d'un index superieur ou inferieur car les anim peuvent changer
           setTimeout(()-> ) permet de mettre un timer 
       */

    changeIndex1To2() {

        var tab = { ...this.state.SideCardStyle }
        var tabText = { ...this.state.TabSideCardText }
        if (!tab[3].includes("hidde") || !tab[0].includes("hidde")) {
            setTimeout(() => {
                this.AppearPage1to4(tab, tabText);
            }, 2 * tmrSlideCard);
        }
        this.changeIndexbetween1and4(tab, tabText);
        if(this.refMain.current !== null)
        {
            this.refMain.current.slideCardDescriChangePage(0);
        }
    }

    AppearPage1to4(tab, tabText) {

        tab[0] = styleSideLeftCard2 + styleHidden + styleLeftAbsolute;
        tab[1] = styleSideLeftCard + styleVisible + lcSlideLeft;
        tab[2] = styleVisible + styleSideRightCard + rcSlideRight;
        tab[3] = styleRightAbsolute + styleHidden + styleRightAbsolute;

        tabText[1] = styleSCTextLeft + fade0to1;
        tabText[2] = styleSCTextRight + fade0to1;
        this.setState({
            SideCardStyle: tab,
            mainCardStyle: mcStyle,
            TabSideCardText: tabText,
        })
        this.resetFadingText();

    }

    changeIndexbetween1and4(tab, tabText) {
        if(this.refMain.current !== null)
        {
            this.refMain.current.slideCardDescriChangePage(0);
        }

        if (tab[3].includes("visible") || tab[0].includes("visible")) {

            if (tab[3].includes("visible")) {
                tab[2] += rcSlideLeft;
                tab[3] += rcSlideLeftLeft;
                this.setState({
                    mainCardStyle: mcStyle,
                    SideCardStyle: tab,
                })
                setTimeout(() => {
                    tab[3] = styleSideRightCard2 + styleHidden;
                    tab[2] = styleSideRightCard + styleHidden;

                    this.setState({
                        SideCardStyle: tab,
                        mainCardStyle: mcStyle + lcSlideRight,
                    })
                }, tmrSlideCard)
            }
            if (tab[0].includes("visible")) {
                tab[0] = styleSideLeftCard2 + lcSlideRight;
                this.setState({
                    SideCardStyle: tab,
                })
                setTimeout(() => {
                    tab[0] = styleSideLeftCard2 + styleHidden;
                    this.setState({
                        SideCardStyle: tab,
                        mainCardStyle: mcStyle + rcSlideLeft,

                    })
                }, tmrSlideCard);
            }
        }
    }

    AppearPage5() {

    }
    //#endregion

    //#region change Page
    async changeIndexTO0() {

        var tab = { ...this.state.SideCardStyle }
        var tabText = { ...this.state.TabSideCardText }
        if (tab[2].includes("hidde")) {
            tab[0] = styleSideLeftCard2 + styleHidden;
            tab[1] = styleSideLeftCard + styleHidden;
            this.setState({
                TabSideCardText: tab,
                mainCardStyle: mcStyle + " rcSlideLeftLeft",
            })
            setTimeout(() => {
                this.setState({
                    SideCardStyle:
                        [styleSideLeftCard2 + styleLeftAbsolute + styleHidden,
                        styleSideLeftCard + styleLeftAbsolute + styleHidden,
                        styleSideRightCard + rcSlideRight,
                        styleSideRightCard2 + rcSlideRight],
                    mainCardStyle: mcStyle,
                    TabSideCardText: [styleSCTextLeft,
                        styleSCTextLeft,
                        styleSCTextRight + fade0to1,
                        styleSCTextRight + fade0to1],


                })
                this.resetFadingText();
            }, tmrSlideCard)

        }
        else {


            if(this.refMain.current !== null)
            {
                this.refMain.current.slideCardDescriChangePage(0);
            }

            tab[1] = styleSideLeftCard + styleVisible + lcSlideRight;

            this.setState({
                SideCardStyle: tab,
                mainCardStyle: mcStyle,
            })
            setTimeout(() => {
                tab[1] = styleSideLeftCard + styleHidden;
                tab[2] = styleSideRightCard + rcSlideLeft;

                this.setState({
                    SideCardStyle: tab,
                    mainCardStyle: mcStyle + rcSlideLeft,
                    TabSideCardText: tabText,
                })
                setTimeout(() => {
                    tab[1] = styleSideLeftCard + styleLeftAbsolute + styleHidden;
                    tab[2] = styleSideRightCard;
                    tab[3] = styleSideRightCard2 + styleVisible + rcSlideRight;
                    tabText[3] = styleSCTextRight + fade0to1;
                    this.setState({
                        SideCardStyle: tab,
                        mainCardStyle: mcStyle,
                        TabSideCardText: tabText,
                    })
                    this.resetFadingText();
                }, tmrSlideCard)
            }, tmrSlideCard)



        }

    }



    async changeIndexTo5() {

        if(this.refMain.current !== null)
        {
            this.refMain.current.slideCardDescriChangePage(0);
        }

        var tab = { ...this.state.SideCardStyle }
        var tabText = { ...this.state.TabSideCardText }

        if (tab[1].includes("hidde")) {
            tab[2] = styleSideRightCard + rcSlideLeft;
            tab[3] = styleSideRightCard2 + rcSlideLeft;
            this.setState({
                mainCardStyle: mcStyle + lcSlideRightRight,
                SideCardStyle: tab,
            })
            setTimeout(() => {
                tab[2] = styleSideRightCard + styleRightAbsolute + styleHidden;
                tab[3] = styleSideRightCard2 + styleRightAbsolute + styleHidden;
                tab[0] = styleSideLeftCard2 + lcSlideLeft;
                tab[1] = styleSideLeftCard + lcSlideLeft;
                this.setState({
                    mainCardStyle: mcStyle,
                    SideCardStyle: tab,
                })
            }, tmrSlideCard)
        }
        else {
            tab[0] = styleSideLeftCard2 + styleHidden + styleLeftAbsolute;
            tab[2] = styleSideRightCard + styleHidden;
            tab[1] = styleSideLeftCard + lcSlideRight;
            tab[3] = styleHidden + styleSideRightCard2 + styleRightAbsolute;
            this.setState({
                mainCardStyle: mcStyle + lcSlideRight,
                SideCardStyle: tab,
            })

            setTimeout(() => {

                tab[2] += styleRightAbsolute;
                tab[1] = styleSideLeftCard;
                tab[0] = styleSideLeftCard2 + styleHidden;

                this.setState({
                    mainCardStyle: mcStyle,
                    SideCardStyle: tab,
                })
                setTimeout(() => {
                    tab[0] = styleSideLeftCard2 + styleVisible + lcSlideLeft;
                    tabText[0] = styleSCTextLeft + fade0to1;
                    this.setState({
                        mainCardStyle: mcStyle,
                        SideCardStyle: tab,
                        TabSideCardText: tabText,
                    })
                    this.resetFadingText();
                }, tmrSlideCard)
            }, tmrSlideCard)
        }


    }

    //#region Deprecated
    async changeIndexTo1() {

        var tab = { ...this.state.SideCardStyle }
        var tabText = { ...this.state.TabSideCardText }
        this.changeIndexbetween1and4(tab, tabText);

        setTimeout(() => {
            this.AppearPage1to4(tab, tabText);
        }, 2 * tmrSlideCard);


    }

    async changeIndexTo3() {

        if(this.refMain.current !== null)
        {
            this.refMain.current.slideCardDescriChangePage(0);
        }
        var tab = { ...this.state.SideCardStyle }
        if (tab[0].includes("visible")) {

            tab[0] = styleSideLeftCard2 + lcSlideRight;
            this.setState({
                SideCardStyle: tab,
            })
            setTimeout(() => {
                tab[0] = styleSideLeftCard2 + styleLeftAbsolute + styleHidden;
                tab[1] = styleSideLeftCard + styleVisible + lcSlideLeft;
                tab[2] = styleSideRightCard + styleVisible + rcSlideRight;
                this.setState({
                    SideCardStyle: tab,
                    mainCardStyle: mcStyle + rcSlideLeft,
                })
                this.resetFadingText();
            }, tmrSlideCard)
        }
    }

    async changeIndexTo4() {

        if(this.refMain.current !== null)
        {
            this.refMain.current.slideCardDescriChangePage(0);
        }
        var tab = { ...this.state.SideCardStyle }
        var tabText = { ...this.state.TabSideCardText }
        if (tab[0].includes("visible")) {

            tab[0] = styleSideLeftCard2 + lcSlideRight;
            tab[1] = styleSideLeftCard + styleVisible;
            this.setState({
                SideCardStyle: tab,

            })
            setTimeout(() => {
                tab[0] = styleSideLeftCard2 + styleHidden;
                tab[1] = styleSideLeftCard + styleVisible + lcSlideRight;
                tabText[1] = styleSCTextLeft + fade0to1;
                this.setState({
                    SideCardStyle: tab,
                    mainCardStyle: mcStyle + rcSlideLeft,

                })
                tab[0] = styleSideLeftCard2 + styleHidden + styleLeftAbsolute;

                tab[2] = styleSideRightCard + styleVisible + rcSlideRight;
                tabText[2] = styleSCTextRight + fade0to1;
                setTimeout(() => {
                    this.setState({
                        SideCardStyle: tab,
                        mainCardStyle: mcStyle,
                        TabSideCardText: tabText,
                    })
                    this.resetFadingText();
                }, tmrSlideCard)
            }, tmrSlideCard / 2)
        }
    }
    //#endregion

    //#endregion
    async onMainCardAnimEnd() {
        await this.rightCardsPop();

    }


    rightCardsPop() {
        setTimeout(() => {
            var tab = { ...this.state.SideCardStyle }
            var tabText = { ...this.state.TabSideCardText }
            tab[2] = styleSideRightCard + styleVisible + rcSlideRight;
            tabText[2] = styleSCTextRight + fade0to1;
            this.setState({
                SideCardStyle: tab,
                TabSideCardText: tabText,
            })

            setTimeout(() => {
                tab[3] = styleSideRightCard2 + styleVisible + rcSlideRight;
                tabText[3] = styleSCTextRight + fade0to1;
                this.setState({

                    SideCardStyle: tab,
                    mainCardStyle: mcStyle,
                    TabSideCardText: tabText,
                })
                this.resetFadingText();
            }, tmrSlideCard);
        }, 850)


    }

    async resetFadingText() {
        setTimeout(() => {
            var tabText = { ...this.state.TabSideCardText }

            tabText[0] = styleSCTextLeft;
            tabText[1] = styleSCTextLeft;
            tabText[2] = styleSCTextRight;
            tabText[3] = styleSCTextRight;

            this.setState({
                TabSideCardText: tabText,
            })
        }, 250)

    }





}

