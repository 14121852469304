import React, { Component } from 'react';
import { Button, Col, Row, Container } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';
import JsxParser from 'react-jsx-parser';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './AdsBanner.css';
import ArrowImage from "../../assets/SVG/send.svg"

class AdsBanner extends Component {
    constructor(props) {
        super(props);

        console.log(props);

        if(this.props.isMobile == true) {
            this.state = { 
                bannerStyle : "bannerMobile", 
            };
        } else {
            this.state = { 
                bannerStyle : "bannerDesktop",
            }
        }
    }

    render() {
        const { translate } = this.props;

        return (
            <a className={this.state.bannerStyle} href="https://hygie.care/epioxdpi" target="_blank">
                <Container className="--fullHeight">
                    <Row className="--fullHeight">
                        <Col xs={{ size : 10}} className="d-flex justify-content-center align-items-center">
                            <span className="banner__text">    
                                {this.props.t("banner__text__write")} <br />{this.props.t("banner__text__in")} <span className="banner__text--bold">{this.props.t("banner__text__dpi")}</span>
                            </span>
                        </Col>
                        <Col xs={{ size : 2}} md={{size : 1 }} className="d-flex justify-content-start align-items-center"> 
                            <img className="banner__arrow" src={ArrowImage}></img>                   
                        </Col>
                    </Row>
                </Container>
            </a>
        );
    }
}

function mapStateToProps(store) {
    return {
        score: store.score.score,
        name: store.name.name,
        connect : store.connect.connect,
    
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch)
}


export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(AdsBanner);


