import React, { Component } from 'react'
import "./WelcomeMessage.css"
import { withTranslation } from 'react-i18next';


class WelcomeMessage extends Component {

    render() {

        const { t } = this.props;

        var textClassName = "Welcome-mssg__titre";
        var subtitleClassName = "Welcome-mssg__subtitle";
        var text76 = " Welcome-mssg__titre--76";
        // if(localStorage.getItem("i18nextLng") == "de")
        // {
            
        //     if(window.innerWidth < 650)
        //     {
        //         textClassName = "Welcome-mssg__titre--DE";
        //         text76 = "";
        //     }
            
        // }
        // else{
        //     if(window.innerWidth < 450)
        //     {
        //         textClassName = "Welcome-mssg__titre--DE";
        //         text76 = "";
        //     }
        // }

        return (

            <div name="viewport" content="width=device-width, initial-scale=1">
                <h1>
                    <span className={textClassName + text76}>{t("welcom")}</span> <br/>
                    <span className={subtitleClassName }>{t("on")}</span>
                    <span className={subtitleClassName +" Welcome-mssg__titre--orange"}> HYGIE </span>
                    <span className={subtitleClassName +" Welcome-mssg__titre--bold"}>KATZ</span>
                </h1>



            </div>
        )
    }
}

export default withTranslation()(WelcomeMessage)


