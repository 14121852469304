
import React, { Component } from 'react'
import { ReactComponent as SelectBarSvg } from "../../../../assets/SVG/m-selectBar.svg";
import './SelectBar.css'




const tab = [0, 42, 84, 123, 161, 200];

export default class SelectBar extends Component {

    constructor(props) {

        super(props);

        this.isBallActive = false;


        this.state = {
            locX: tab[this.props.index],
            x:0

        }
    }

    svgAnimate(newX, index) {
        this.setState({
            x: newX
        })
        this.props.selectPage(index);
    }


    onSlideSelect(e) {

        if (this.isBallActive) {
            var posX = document.getElementById('barre').getBoundingClientRect().x;
            
            var x = e.clientX - posX - 8.5;
            if(x>=-5 && x<=205)
            {
                this.setState({ locX: x })
            }

         
        }

    }

    onslideSelectMobile(e){
        if (this.isBallActive) {
            var posX = document.getElementById('barre').getBoundingClientRect().x;
            var  x = e.targetTouches[0].clientX- posX - 8.5;
            
            if(x>=-5 && x<=205)
            {
                this.setState({ locX: x })
            }
            
            
        }
       
    }

    activeBall(e) {
        this.isBallActive = true;
        
       
    }

    unActiveBall(e) {
        this.isBallActive = false;
        this.selectionPage();
      
    }

    onChange() {
        var x = this.state.locX;
        return (x)
    }

    selectionPage() {
        var x = this.state.locX;
        var int = 0;
     
        if (x < 21) {
            this.props.selectPage(0)
            int = 0;
        }

        else if (x >= 21 && x <= 63) {
            this.props.selectPage(1)
            int = 1;
        }

        else if (x > 63 && x <= 103.5) {
            this.props.selectPage(2)
            int = 2;
        }

        else if (x > 103.5 && x <= 142) {
            this.props.selectPage(3)
            int = 3;
        }

        else if (x > 142 && x <= 180.5) {
            this.props.selectPage(4)
            int = 4;
        }
        else {
            this.props.selectPage(5)
            int = 5;
        }

        this.setState({locX : tab[int]});
    }






    render() {
        if(this.isBallActive == false)
        {
            this.state.locX = tab[this.props.index];
        }

        var oldX=0;
        var oldY=0;
        var newX = window.innerWidth;
        var newY = window.innerHeight;
        if(oldX!=newX && oldY!=newY){
        }
        oldX=newX;
        oldY=newY;

        return (
            <div className="select--bar__container"
                //Web
                onMouseDown={(e) => this.activeBall(e)}
                onMouseMove={(e) => this.onSlideSelect(e)}
                onMouseUp={(e) => this.unActiveBall(e)}
                
                //Mobile
                onTouchStart={(e) => this.activeBall(e)}
                onTouchMoveCapture={(e) => this.onslideSelectMobile(e)}
                onTouchEnd={(e) => this.unActiveBall(e)}>

                <svg width="230" height="17" viewBox="0 0 210 17" fill="none" xmlns="http://www.w3.org/2000/svg" id="barre">
                    <rect x="1" y="6" width="209" height="6" rx="3" fill="#544D56" />

                    <rect onClick={() => this.svgAnimate(200, 5)} x="200" y="4" width="10" height="10" rx="5" fill="#544D56" />
                    <rect onClick={() => this.svgAnimate(161, 4)} x="161" y="4" width="10" height="10" rx="5" fill="#544D56" />
                    <rect onClick={() => this.svgAnimate(123, 3)} x="123" y="4" width="10" height="10" rx="5" fill="#544D56" />

                    <rect onClick={() => this.svgAnimate(84, 2)} x="84" y="4" width="10" height="10" rx="5" fill="#544D56" />
                    <rect onClick={() => this.svgAnimate(42, 1)} x="42" y="4" width="10" height="10" rx="5" fill="#544D56" />
                    <rect onClick={() => this.svgAnimate(1, 0)} y="4" width="10" height="10" rx="5" fill="#544D56" />

                    <rect id="ball" width="17" height="17" rx="8.5" fill="#FE5000" x={this.state.locX}
                        className="select--bar__ball"

                    />

                </svg>
                <div className="tempo"> </div>
            </div>
        )
    }
}
