import React, { Component } from 'react'
import "./Connection.css"
import { withTranslation } from 'react-i18next';
import JsxParser from 'react-jsx-parser'
import WrapperButton from '../../../../components/WrapperButton/WrapperButton';
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { Col, Row, Container } from 'reactstrap';
class Connection extends Component {

    render() {

        const { t } = this.props;
        const url = this.props.url;

        return (
            <Row className="d-flex align-items-center mt-4">
                <Col xs={{size:6}} >
                    <NavLink to={{
                        pathname: '/Registration',
                        state: { url: url }
                    }}>
                        <WrapperButton
                            onPressSubmit={()=>({})}
                            text={"inscription"}
                            disabled={true}>
                        </WrapperButton>
                    </NavLink>
                </Col>
                <Col xs={{size:6}} className="d-flex justify-content-center">
                    <NavLink
                        to={{
                            pathname: '/connect',
                            state: { url: url }
                        }}>
                        
                        <div className="connect__text--btn--register">{t("connect")}</div>
                    </NavLink>
                </Col>
            </Row>
        )
    }
}

export default withTranslation()(Connection)
