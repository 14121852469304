import React, { Component } from 'react'
import './Input.css'
import { ReactComponent as Pen } from '../../../../assets/SVG/pen.svg'
import { withTranslation } from 'react-i18next';
import { ReactComponent as Validation } from '../../../../assets/SVG/validation.svg'
import InputMask from 'react-input-mask';
import MaterialInput from '@material-ui/core/Input';
import { RepeatRounded } from '@material-ui/icons';
import { Link, NavLink } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

class Input extends Component {

    constructor(props) {
        super(props);
        this.index =0
        this.state = {
            inputValue: "",
            isValidScore: false,

        }
        this.startvalue();
    }
    componentDidMount() {
        var str = "";
        this.props.score.forEach(element => {
            str += element;
        });

        this.setState({ inputValue: str })
    }


    startvalue() {
        var x = this.props.score + ''
        this.setState({ inputValue: x })
    }

    componentDidUpdate(prevprops) {
        var value = "";
        var index = 0;
        this.props.score.forEach(element => {
            
            if (element === "") {
                value += "0";
            }
            else {
                value += element;
            }
            
            

        });
        var valNoSpace = this.state.inputValue.replace(/ /g, '');
        if (value != valNoSpace) {
            var str = value[0] + value[1] + " "+ value[2] + value[3]+ " "+ value[4]+ value[5]
            this.setState({
                inputValue: str,
            })
        }



    }

    render() {

        const { t } = this.props;
        let compo;
        return (

            <div className="">
                <span className="text">{t("yourKatzScore")}</span> <br></br>

                <div className="input__parent" >
                    <InputMask type="text" value={this.state.inputValue} maskChar='0'
                        mask="99 99 99" placeholder="55  33  22"
                        className={"input__normal"}
                        onChange={this.onChange.bind(this)}
                        id='input'
                        onClick={(e) => this.onChangePage(e)}
                    />
                    {this.SwitchSvgPath()}

                </div>

            </div>
        )
    }

    onChangePage(e) {
        var posX = e.pageX - document.getElementById('input').getBoundingClientRect().x;
        if (window.location.pathname === '/calculatekatz') {
            if (posX <= 28.5) {
                this.index =0;
                this.props.selectPage(0)
            }
            else if (posX > 28.5 && posX <= 42.5) {
                this.index =1;
                this.props.selectPage(1)
            }
            else if (posX > 42.5 && posX <= 56.5) {
                this.index =2;
                this.props.selectPage(2)
            }
            else if (posX > 56.5 && posX <= 70.5) {
                this.index =3;
                this.props.selectPage(3)
            }
            else if (posX > 70.5 && posX <= 84.5) {
                this.index =4;
                this.props.selectPage(4)
            }
            else {
                this.index =5;
                this.props.selectPage(5)
            }
        }


    }


    switchSvg() {
        var bool = true;
        var temp = this.props.score;

        for (var i = 0; i < this.props.score.length; i++) {
            if (temp[i] == "0" || temp[i] == "") {
                bool = false;
            }
        }
        if (bool) {
            if (this.props.score.length <= 5) {
                return (<div className="input__btn--pen"><Pen/></div>)

            }
            else {  
                var path = "/resultkatz";
               if(!this.props.connect){
                if(localStorage.getItem("resultat") <= 0){
                    path = "/quota"
                 }
                else{path = "/resultkatz";}
               }
                
                return (<NavLink to={path} className="input__btn--pen"> <Validation /></NavLink>)
                
            }
        }
        else {
            return (<div className="input__btn--pen"><Pen/></div>)
        }
    }

    SwitchSvgResult() {
        var bool = true;
        var temp = this.props.score;

        for (var i = 0; i < this.props.score.length; i++) {
            if (temp[i] == "0" || temp[i] == "") {
                bool = false;
            }
        }
        if (bool) {
            if (this.props.score.length <= 5) {
                return (<Pen className="input__btn--pen"></Pen>)

            }
            else {
                return (<div className="input__btn--pen"><Validation/></div>)
            }
        }
        else {
            return (<Pen className="input__btn--pen"></Pen>)
        }
    }

    SwitchSvgPath() {
        if (window.location.pathname === '/resultkatz') {
            return (this.SwitchSvgResult())
        }
        else {
            return (this.switchSvg())
        }
    }

    onChange(event) {
        var str = event.target.value
        var bool = this.checkEntry(str)

        this.selectPageIndex(str);

        if (bool) {
            this.setState({
                inputValue: str,
            })
            var valNoSpace = str.replace(/ /g, '');
            this.props.changeKatzScoreWithStr(valNoSpace);
        }
        else {
            this.setState({
                inputValue: this.state.inputValue,
            })
        }


    }

    selectPageIndex(newVal) {
        var value = newVal.replace(/ /g, '');
        var tabValue = value.split("");

        var inputVal = this.state.inputValue.replace(/ /g, '');
        var tabInput = inputVal.split("");
        for (let i = 0; i < tabValue.length; i++) {
            var val = parseInt(tabValue[i]);
            var inp = parseInt(tabInput[i]);

            if (val >= 0) {
                if (!(val === inp)) {
                    if(val === 0)
                    {
                        this.index = i;
                    }
                    else{
                        this.index = i + 1;
                    }

                }
            }

        }

        if (this.index >= 6) {
            this.index = 6;
        }
        if(this.index <0)
        {
            this.index =0;
        }
        if(window.location.pathname==='/calculatekatz'){
            this.props.selectPage(this.index);
        }
        

    }

    checkEntry(value) {

        var tabValue = value.replace(/ /g, '');

        tabValue = tabValue.split("");
        var bool = true;

        tabValue.forEach(element => {
            var num = parseInt(element);

            if (!isNaN(num)) {
                if (element >= 0 && element < 5) {



                }
                else {
                    bool = false;
                }

            }
            else {
                bool = false;
            }


        });

        return bool;
    }

    onValueChange(e) {

        var strEntry = this.getInputValueWithoutSpace(e.target.value);

        var entry = this.getEntry(strEntry);

        if (entry !== null) {
            if (entry == "Backspace") {
                this.setState({
                    inputValue: this.setSpaceToStringInput(strEntry),
                })



                this.props.changeKatzScoreWithStr(strEntry);
            }
            else if (this.isEntryValid(entry)) {

                if (this.isLenghtValid(strEntry)) {

                    this.setState({
                        inputValue: this.setSpaceToStringInput(strEntry),
                    })
                    this.props.changeKatzScoreWithStr(strEntry);
                }

            }

        }

    }



    isLenghtValid(strEntry) {
        var tabEntry = strEntry.split("");
        if (tabEntry.length < 7) {
            return true;
        }
        return false;
    }

    isEntryValid(entry) {
        var number = parseInt(entry);
        if (number > 0 && number < 5) {
            return true;
        }
        return false;
    }

    setSpaceToStringInput(value) {
        var str = value.replace(/(\d{2}(?!$))/g, "$1 ");
        return str;
    }

    getInputValueWithoutSpace(value) {
        var str = value.replace(/\s/g, '');

        return str;
    }

    getEntry(str) {

        var tabEntry = str.split("");
        var input = this.state.inputValue.split("");
        var tabScore = this.props.score;
        if (tabEntry.length < input.length) {
            return "Backspace"
        }
        for (let i = 0; i < tabEntry.length; i++) {

            if (tabEntry[i] != tabScore[i]) {

                return tabEntry[i];
            }
        }
        return null;
    }
}
function mapStateToProps(store) {

    let display = '';
    if (typeof store.footer.display !== 'undefined') {
        display = store.footer.display;


    }

    return {
        display: display,
        score: store.score.score,
        connect : store.connect.connect
    };
}
export default compose(withTranslation(), connect(mapStateToProps))(Input);
