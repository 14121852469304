import React, { Component } from 'react';
import { Button, Col, Row, Container } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';
import WelcomeMessage from './Components/WelcomeMessage/WelcomeMessage';
import Using from './Components/Using/Using';
import Connection from './Components/Connection/Connection';
import "./Welcome.css"
import { Style } from '@material-ui/icons';
import JsxParser from 'react-jsx-parser';
import { withTranslation } from 'react-i18next';
import { changeDisplayFooter } from '../../services/redux/actions/FooterAction'
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { changeScoreKatz } from '../../services/redux/actions/ScoreAction';


class Welcome extends Component {

    constructor(props) {
        super(props);
        this.style="";
        
        this.props.changeScore(['0','0','0','0','0','0'])
    }

    displayFooter(){
        if(this.props.connect === true)
        {
            this.props.changeDisplayFooter(true);
        }
        else{
            this.props.changeDisplayFooter(false);
        }
    }

    componentDidMount() {
        document.title = "Hygie Katz - Home"
        this.displayFooter();
    }
    render() {
       
        const { t } = this.props
        if(!this.props.connect){this.style=""}
        else{this.style = "Welcome__SecondRow"}
        return (
            <Container className="">
                <Row className="Welcome__FirstRow mt-md-5">
                    <Col xs={{ size: 12 }} md={{size: 5, offset: 1}}>
                        <WelcomeMessage></WelcomeMessage>
                    </Col>
                </Row>

                <Row className="d-flex align-items-end rowHeight">
                    <Col className="" xs={{ size: 10 }} md={{size: 5, offset: 1}}>
                        <Using></Using>
                    </Col>
                    {this.onDisplayConnect()}
                </Row>
                
                
            </Container>
        )
    }

    //Affiche le texte et les boutons d'inscription / login si on est déjà connecté
    onDisplayConnect() { 
        if (!this.props.connect) {
            return (
                <Col className="mt-5" xs={{ size: 12 }} md={{size: 4, offset : 1}}>  
                    <Col className="--paddingL-0" xs={{ size: 10 }} md={{size: 12}}>
                        <div className="connect__text"><JsxParser jsx={this.props.t("know_app")} /> </div>
                        <div className="connect__text mt-3"><JsxParser jsx={this.props.t("free_app")} /> </div>
                    </Col>
                    <Connection url={window.location.pathname}></Connection>
                </Col>
            )
        }
    }
}


function mapStateToProps(store) {
    return {
        connect: store.connect.connect
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeDisplayFooter: changeDisplayFooter,
        changeScore : changeScoreKatz
    }, dispatch)
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Welcome);






