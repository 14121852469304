import React, { Component } from 'react'

import { Col, Row, Container } from 'reactstrap';
import Header from './components/Header/Header';
import Welcome from './scenes/Welcome/Welcome';
import Footer from './components/Footer/Footer';
import ResultKatz from './scenes/ResultKatz/ResultKatz';
import Connect from './scenes/Connect/Connect'
import Quota from './scenes/Quota/Quota';
import Registration from './scenes/Registration/Registration';
import ConfirmSubscription from './scenes/ConfirmSubscription/ConfirmSubscription';
import UsingCondition from './scenes/UsingCondition/UsingCondition';

import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import CalculateKatz from './scenes/CalculateKatz/CalculateKatz';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Explication from './scenes/Explication/Explication';
import ForgotPassword from './scenes/ForgotPassword/ForgotPassword';



class App2 extends Component {
    render() {
        return (
            <Router>
                <Header />

                <Route exact path="/" component={Welcome} />
                {this.displayPage()}
                <Route exact path="/connect" component={Connect} />
                <Route exact path="/registration" component={Registration} />
                <Route exact path="/confirmSubcription" component={ConfirmSubscription}></Route>
                <Route exact path="/Condition" component={UsingCondition}></Route>
                <Route exact path="/tutorial" component={Explication} />
                <Route exact path="/forgotPassword" component={ForgotPassword}></Route>
                <Redirect to="/"></Redirect>

                <Footer />                            
            </Router>
        )
    }

    displayPage() {
        if (this.props.connect) {
            return (
                <div>
                    <Route exact path="/calculatekatz" component={CalculateKatz} />
                    <Route exact path="/resultkatz" component={ResultKatz} />
                    <Route exact path="/quota" component={Quota} />
                </div>
            )
        }
        else {
            if (localStorage.getItem("resultat") > 0) {
                return (
                    <div>
                        <Route exact path="/calculatekatz" component={CalculateKatz} />
                        <Route exact path="/resultkatz" component={ResultKatz} />
                        <Route exact path="/quota" component={Quota} />
                    </div>
                )
            }
            else {
                return (
                    <div>
                        <Route exact path="/quota" component={Quota} />
                    </div>
                )
            }
        }
    }
}
var value = localStorage.getItem("resultat");
if (value === null) {
    // Si value est égal à null c'est que c'est la première fois que l'appli est ouvert sur ce navigateur et donc je l'initialise à 3
    localStorage.setItem("resultat", 3);
}

function mapStateToProps(store) {
    return {
        connect: store.connect.connect
    };
}

export default compose(withTranslation(), connect(mapStateToProps, null))(App2);